import React, { FC, useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputSelect from 'components/commonComponents/form/inputSelect';
import {
	generallyTabCheckBox,
	GewerbeImmobilie,
	GewerbeImmobilieOption,
	GewerbeImmobilieSubOption,
	grundstückAccommodationOption,
	hausAccommodationOption,
	myPropertyRules,
	ParkflächeAccommodationOption,
	propertyOption,
	propertyTab,
	requiredFields,
	wohnungAccommodationOption
} from '../utils/constants';
import PropertyButtonDiv from './propertyButtonDiv';
import { CustomCheckBox } from 'components/commonComponents/form/checkBox/checkbox';
import { PropertyTabProps } from '../utils/myProperty.interface';
interface GenerallyTabForm {
	objectTypeSub: string;
	newObjectSubType: string;
	typePropertyUse: any;
}
const checkBoxHideOption = ['Forst', 'Landwirtschaft', 'Grünland'];
const checkBoxHideTypes = [propertyOption.commercial, propertyOption.parking];

const GenerallyTab: FC<PropertyTabProps> = ({ gotoBack, selectedValue, isLoading }) => {
	const {
		control,
		setValue,
		watch,
		clearErrors,
		formState: { errors }
	} = useFormContext<GenerallyTabForm>();

	const [isCheckedBoxShow, setIsCheckedBoxShow] = useState(true);
	const getOptions = useCallback(() => {
		if (selectedValue === propertyOption.apartment) {
			return {
				option: wohnungAccommodationOption,
				label: 'Welche Art von Wohnung bieten Sie an?'
			};
		} else if (selectedValue === propertyOption.house) {
			return {
				option: hausAccommodationOption,
				label: 'Welche Art von Haus bieten Sie an?'
			};
		} else if (selectedValue === propertyOption.commercial) {
			const newObjectSubType: any = watch('newObjectSubType');
			let options = GewerbeImmobilieSubOption[GewerbeImmobilie.AGRICULTURE];
			if (newObjectSubType) {
				options = GewerbeImmobilieSubOption[newObjectSubType.id as GewerbeImmobilie];
			}
			return {
				option: options,
				label: 'Welche Art von Gewerbeimmobilie Sie an?'
			};
		} else if (selectedValue === propertyOption.parking) {
			return {
				option: ParkflächeAccommodationOption,
				label: 'Welche Art von Parkplätze Sie an?'
			};
		}
		return {
			option: grundstückAccommodationOption,
			label: 'Welche Art von Grundstück bieten Sie an?'
		};
	}, [selectedValue, watch]);

	const hideCheckBox = useMemo(() => !checkBoxHideTypes.includes(selectedValue as propertyOption), [selectedValue]);
	const isCommercial = useMemo(() => selectedValue === propertyOption.commercial, [selectedValue]);

	const handleObjectTypeSubChange = (e: any, onChange: any) => {
		onChange(e);
		if (hideCheckBox) {
			// no need to go further if checkbox is nor there
			return;
		}
		if (checkBoxHideOption.includes(e.value)) {
			setIsCheckedBoxShow(false);
			return;
		} else {
			setIsCheckedBoxShow(true);
		}

		if (e.value) {
			// Auto-check "Gewerbe" or "Wohnen" based on selected option
			if (e.value.toLowerCase().includes('gewerbe')) {
				setValue('typePropertyUse', ['Gewerbe']);
			} else if (e.value.toLowerCase().includes('wohnung')) {
				setValue('typePropertyUse', ['Wohnen']);
			} else {
				setValue('typePropertyUse', []); // Reset if no match
			}
			clearErrors('typePropertyUse');
		}
	};

	return (
		<div className='generally-tab'>
			{isCommercial && (
				<Controller
					name='newObjectSubType'
					control={control}
					rules={myPropertyRules.newObjectSubType}
					render={({ field: { ref, onChange, ...rest } }) => {
						return (
							<InputSelect
								label={'Welche Art von Gewerbeimmobilie Sie an?'}
								placeholder='Bitte wählen'
								options={GewerbeImmobilieOption}
								asterisk={requiredFields.includes('newObjectSubType')}
								onChange={(e: any) => {
									onChange(e);
									setValue('objectTypeSub', '');
								}}
								error={errors.newObjectSubType}
								{...rest}
							/>
						);
					}}
				/>
			)}
			<Controller
				name='objectTypeSub'
				control={control}
				rules={myPropertyRules.objectTypeSub}
				render={({ field: { ref, onChange, ...rest } }) => {
					return (
						<InputSelect
							label={getOptions().label}
							placeholder='Bitte wählen'
							options={getOptions().option}
							asterisk={requiredFields.includes('objectTypeSub')}
							onChange={(e: any) => handleObjectTypeSubChange(e, onChange)}
							error={errors.objectTypeSub}
							disable={isCommercial && !watch('newObjectSubType')}
							{...rest}
						/>
					);
				}}
			/>

			{!isCheckedBoxShow && hideCheckBox && <div className='empty-div'></div>}
			{isCheckedBoxShow && hideCheckBox && (
				<div>
					<div className='flex align__items--center'>
						<label className='form-label'>Welche Art der Nutzung ist möglich?</label>
						<span className='form-asterisk'>&nbsp;*</span>
					</div>
					<div>
						<div className={'flex justify__content--between generally-tab-check-box'}>
							<Controller
								name='typePropertyUse'
								control={control}
								rules={myPropertyRules.typePropertyUse}
								render={({ field: { ref, ...rest } }) => {
									let fieldValue = rest.value || [];
									return (
										<>
											{generallyTabCheckBox.map((item, i) => (
												<CustomCheckBox
													{...rest}
													key={i}
													checked={fieldValue.includes(item.label)}
													label={item.label}
													customOnChange={(isChecked) => {
														if (fieldValue.includes(item.label) && !isChecked) {
															fieldValue = fieldValue.filter(
																(i: string) => i !== item.label
															);
														} else {
															fieldValue.push(item.label);
														}
														setValue(`typePropertyUse`, fieldValue);
														clearErrors('typePropertyUse');
													}}
												/>
											))}
										</>
									);
								}}
							/>
						</div>
						{errors && <span className='error-message'>{(errors as any).typePropertyUse?.message}</span>}
					</div>
				</div>
			)}
			<div className={`${isCommercial ? 'min-height-250' : ''}`}>
				<PropertyButtonDiv
					backButton={false}
					showSpinner={isLoading}
					goBack={() => gotoBack(propertyTab.addPropertyTab)}
				/>
			</div>
		</div>
	);
};
export default GenerallyTab;
