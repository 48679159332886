import React, { useState } from 'react';
import PropTypes from 'prop-types';
import englishText from 'languages/english';
import getLocalizeText from 'utils/getLocalizeText';
import SwiperGallery from './swiperGallery';
import { isParking, openObjectDetail } from 'components/finder/utils/commonFunctions';
import { IPropertyCardProps } from '../interface/dashboard.interface';
import CustomSpinner from 'components/commonComponents/customSpinner';
import MyProperty from 'components/newMyProperty/container/myProperty';
import { API_CONFIG, HttpService } from 'services';
import { formatCurrency } from 'utils';
import Modal from 'components/modal';

const PropertyCard = (props: IPropertyCardProps) => {
	const {
		isFullMapScreen,
		listItem,
		highlightclass,
		addHighLightClass,
		removeHighlight,
		imageLoadedList,
		index,
		hoveredId,
		setImageLoadedList,
		isShowExtraFeatures = false
	} = props;

	const [editProperty, setEditProperty] = useState({
		isEdit: false,
		listItem: {}
	});
	const [isEdit, setIsEdit] = useState(false);

	const PropertyDetail = (id: string) => {
		setIsEdit(true);
		HttpService.get(`${API_CONFIG.path.objectDetails}/${id}`)
			.then((response) => {
				setEditProperty({
					isEdit: !editProperty.isEdit,
					listItem: response.data
				});
				setIsEdit(false);
			})
			.catch((err) => {
				console.log('err:', err);
				setIsEdit(false);
			});
	};
	return (
		<div className={`column-wrapper  ${isFullMapScreen ? 'column-w-100' : ''}`}>
			<div
				key={listItem.id}
				className={`property-card d-flex pointer ${
					highlightclass !== '' && highlightclass === listItem.id && 'highlight'
				}`}
				style={{ animationDelay: `${index * 0.2}s` }}
				onClick={(event) => listItem.isActive && listItem.isEnable && openObjectDetail(event, listItem.id)}
				onMouseEnter={() => addHighLightClass(listItem.id)}
				onMouseLeave={() => removeHighlight(listItem.id, true)}
			>
				<div className='carousel-wrapper'>
					{!imageLoadedList.includes(listItem.id) && (
						<div
							className='text-center'
							style={{
								height: '83%'
							}}
						>
							{[1, 2, 3].map((count) => (
								<CustomSpinner key={count} className='spin-img' animation='grow' />
							))}
						</div>
					)}
					<div
						className={listItem.isActive && listItem.isEnable ? 'search-img1' : 'search-img1 disable-div'}
						title={
							listItem.isActive && listItem.isEnable
								? ''
								: getLocalizeText(englishText.OBJECT_DISABLED_ERROR)
						}
						style={!imageLoadedList.includes(listItem.id) ? { display: 'none' } : { opacity: '1' }}
					>
						<SwiperGallery
							objectPictures={listItem.objectPictures}
							setImageLoadedList={setImageLoadedList}
							id={listItem.id}
							hoveredId={hoveredId}
							isFullMapScreen={isFullMapScreen}
							isdisable={!listItem.isActive || !listItem.isEnable}
							isEdit={listItem.is_user_matching_property}
							handleEdit={(e) => {
								e.stopPropagation();
								PropertyDetail(listItem.id);
							}}
							isShowExtraFeatures={isShowExtraFeatures}
						/>
					</div>

					{(!listItem.isActive || !listItem.isEnable) && (
						<div className='disable-div-icon'>{getLocalizeText(englishText.DEACTIVATED)}</div>
					)}

					<div className='justify-content-between'>
						<p className='ml-3 adr-label text-truncate'>
							{listItem.propertyType
								? `${getLocalizeText(listItem.propertyType)}  
                					${listItem.adrPostalCode && ' in ' + listItem.adrPostalCode} ${
										listItem.adrQuarter && ' ' + listItem.adrQuarter
											? listItem.adrQuarter
											: listItem.city
								  }`
								: `${listItem.adrStreet || '-'}`}
						</p>
						<div className='d-flex justify-content-between'>
							<div className='d-flex'>
								<p className='location font-bold mb-0'>
									<li>{`${listItem.areaLiving ? Math.round(listItem.areaLiving) + ' m²' : '-'} `}</li>
								</p>
								{!isParking(listItem.object_type) && (
									<p className='location font-bold mb-0'>
										<li>
											{listItem.rooms || '-'} {getLocalizeText(englishText.ROOM)}
										</li>
									</p>
								)}
							</div>
							<div>
								<p className='price-label pt-0'>
									{listItem.price || formatCurrency(listItem.totalRent) || '-'}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{editProperty.isEdit && (
				<MyProperty
					closeModal={() => setEditProperty({ ...editProperty, isEdit: false })}
					objectDetail={editProperty.listItem}
				/>
			)}
			{isEdit && (
				<Modal className={'flex align__items--center justify__content--center transparent-spinner'}>
					<CustomSpinner
						animation='border'
						className='spinner-border'
						style={{ width: '3rem', height: '3rem' }}
					/>
				</Modal>
			)}
		</div>
	);
};

PropertyCard.propTypes = {
	listItem: PropTypes.object.isRequired,
	imageLoadedList: PropTypes.array.isRequired,
	highlightclass: PropTypes.string.isRequired,
	addHighLightClass: PropTypes.func.isRequired,
	removeHighlight: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
	hoveredId: PropTypes.string.isRequired,
	setImageLoadedList: PropTypes.func.isRequired,
	isFullMapScreen: PropTypes.bool.isRequired,
	changeScrollTop: PropTypes.func.isRequired
};

PropertyCard.defaultProps = {
	imageLoadedList: [],
	isFullMapScreen: false
};

export default PropertyCard;
