import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination, Navigation } from 'swiper/modules';
import TextEditor from 'components/commonComponents/reactQuill/reactQuill';
import {
	apartmentDetailTabAreaField,
	ausstattungField,
	houseDetailTabAreaField,
	landDetailTabAreaField,
	landTypes,
	myPropertyRules,
	parkingDetailTabAreaField,
	propertyOption,
	propertyTab,
	requiredFields
} from '../utils/constants';
import PropertyButtonDiv from './propertyButtonDiv';
import { CustomCheckBox } from 'components/commonComponents/form/checkBox/checkbox';
import InputField from 'components/commonComponents/form/inputText';
import UploadPDF from './uploadPDF';
import ReactQuill from 'react-quill';
import { PropertyTabProps } from '../utils/myProperty.interface';
import { SliderLeftArrowButton, SliderRightArrowButton } from 'components/commonComponents/icons';
import customImage from '../../../assets/images/custom-image.png';
import { isParking } from 'components/finder/utils/commonFunctions';
// Import Swiper styles
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css';

interface DetailTabForm {
	areaLiving: string;
	areaPlot: number;
	noOfRooms: number;
	constructionYear: number;
	vacantFrom: string;
	equipBalcony: boolean;
	equipGarden: boolean;
	equipWinterGarden: boolean;
	objCellar: boolean;
	equipKitchen: boolean;
	objHeatingType: boolean;
	equipRoofTerrace: boolean;
	equipGuestToilet: boolean;
	equipTerrace: boolean;
	description: string[];
	objectTitlePicture: string;
	adPicturesUrls: string[];
}

type Area = 'areaLiving' | 'areaPlot' | 'noOfRooms' | 'vacantFrom' | 'constructionYear';

type Ausstattung =
	| 'equipBalcony'
	| 'equipGarden'
	| 'equipWinterGarden'
	| 'objCellar'
	| 'equipKitchen'
	| 'objHeatingType'
	| 'equipRoofTerrace'
	| 'equipGuestToilet'
	| 'equipTerrace';

const DetailTab: FC<PropertyTabProps> = ({ gotoBack, selectedValue, isLoading }) => {
	const {
		control,
		setValue,
		getValues,
		formState: { errors }
	} = useFormContext<DetailTabForm>();

	const objectTitlePictureURL = useMemo(() => getValues('objectTitlePicture'), [getValues('objectTitlePicture')]);
	const setTitlePicture = useCallback((url: string) => setValue('objectTitlePicture', url), [setValue]);

	const [detailTabAreaField, setDetailTabAreaField] = useState(apartmentDetailTabAreaField);
	const [uploadImage, setUploadImage] = useState<string[]>([]);
	const [renderImage, setRenderImage] = useState([] as string[]);

	const quillRef = useRef<ReactQuill | null>(null);
	const [mainImage, setMainImage] = useState(objectTitlePictureURL);
	const today = new Date().toISOString().split('T')[0];

	useEffect(() => {
		if (selectedValue === propertyOption.apartment) {
			setDetailTabAreaField(apartmentDetailTabAreaField);
		} else if (selectedValue === propertyOption.house) {
			setDetailTabAreaField(houseDetailTabAreaField);
		} else if (selectedValue === propertyOption.land) {
			setDetailTabAreaField(landDetailTabAreaField);
		} else if (selectedValue === propertyOption.commercial) {
			setDetailTabAreaField(apartmentDetailTabAreaField);
		} else {
			setDetailTabAreaField(parkingDetailTabAreaField);
		}
	}, [selectedValue]);

	const handleDeleteImage = useCallback(
		(url: string) => {
			const newData = uploadImage.filter((item) => item !== url);
			setValue('adPicturesUrls', newData);
			setUploadImage(newData);
		},
		[setValue, uploadImage]
	);
	const myRenderItem = useCallback(
		(url: string) => (
			<div className='image-wrapper position-relative'>
				<img className={`image ${mainImage === url ? 'mainImage-image' : ''}`} src={url} alt={'my-property'} />
				{/* Hide buttons when hover on placeholder image */}
				{!url.includes('data:image') && (
					<div className='flex flex--column position-absolute info'>
						<button
							className='gallery-button text--left'
							type='button'
							onClick={() => {
								setTitlePicture(url);
								setMainImage(url);
							}}
						>
							{mainImage === url ? 'Hauptbild' : 'Als Haupt auswählen'}
						</button>
						<button
							type='button'
							className='gallery-button gallery-delete-button text--left'
							onClick={() => handleDeleteImage(url)}
						>
							Löschen
						</button>
					</div>
				)}
			</div>
		),
		[handleDeleteImage, mainImage, setTitlePicture]
	);

	useEffect(() => {
		let arr: string[] = Array.from(
			{ length: Math.max(9, uploadImage.length) },
			(_, index) => uploadImage[index] || customImage
		).map((url) => url);
		if (uploadImage.length > 0 && objectTitlePictureURL === undefined) {
			setTitlePicture(uploadImage[0]);
			setMainImage(uploadImage[0]);
		}
		setRenderImage(arr);
	}, [uploadImage, objectTitlePictureURL, setTitlePicture]);

	return (
		<div>
			<div className={`${selectedValue !== propertyOption.land ? 'detail-tab-area-field' : ''}`}>
				{detailTabAreaField.map((item: { label: string; name: string; placeholder: string }) => {
					const isVacantFrom = item.name === 'vacantFrom';
					const isAreaField = item.name === 'areaLiving' || item.name === 'areaPlot';
					return (
						<div className={item.name === 'areaPlot' ? '' : 'input-field-wrapper'} key={item.label}>
							<Controller
								name={item.name as Area}
								control={control}
								rules={myPropertyRules[item.name]}
								render={({ field: { ref, ...rest } }) => (
									<InputField
										label={item.label}
										asterisk={requiredFields.includes(item.name)}
										error={errors[item.name as Area]}
										type={`${isVacantFrom ? 'date' : 'number'}`}
										addOn={`${isAreaField ? 'm<sup>2</sup>' : ''}`}
										placeholder={item.placeholder}
										className={`${isVacantFrom ? 'date-picker' : 'pr-5 '}`}
										min={today}
										{...rest}
									/>
								)}
							/>
						</div>
					);
				})}
			</div>
			{!landTypes.includes(selectedValue) && (
				<div>
					<label className='form-label'>Ausstattung:</label>
					<div className='detail-tab-area-field'>
						{ausstattungField?.map((item) => (
							<Controller
								key={item.name}
								name={item.name as Ausstattung}
								control={control}
								render={({ field: { ref, value, ...rest } }) => (
									<CustomCheckBox
										checked={value}
										label={item.label}
										className={'flex justify__content--between '}
										{...rest}
									/>
								)}
							/>
						))}
					</div>
				</div>
			)}
			<div>
				<label className='form-label'>Bitte beschreibe Lage, Ausstattung und Zustand.</label>
				<Controller
					name='description'
					control={control}
					render={({ field: { ref, ...rest } }) => (
						<TextEditor
							quillRef={quillRef}
							className='detail-tab-description'
							errorTxt={errors.description as any}
							{...rest}
						/>
					)}
				/>
			</div>
			<div>
				{!isParking(selectedValue) && (
					<label className='form-label text--center'>Bilder- und Dateien hochladen!</label>
				)}
				<UploadPDF setUploadImage={setUploadImage} selectedValue={selectedValue} />
			</div>
			<div className='detail-tab-image-slider'>
				<Swiper
					slidesPerView={3}
					spaceBetween={10}
					navigation={{
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}}
					modules={[Pagination, Navigation]}
					className='mySwiper'
					cssMode={true}
				>
					{renderImage.map((url, i) => (
						<SwiperSlide key={`${url}_${i}`}>{myRenderItem(url)}</SwiperSlide>
					))}
					<div className='swiper-button-prev'>
						<SliderLeftArrowButton />
					</div>
					<div className='swiper-button-next'>
						<SliderRightArrowButton />
					</div>
				</Swiper>
			</div>

			<PropertyButtonDiv
				backButton={true}
				showSpinner={isLoading}
				goBack={() => gotoBack(propertyTab.location)}
			/>
		</div>
	);
};

export default DetailTab;
