import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputRange from 'react-input-range';
import PropTypes from 'prop-types';
import getLocalizeText from 'utils/getLocalizeText';
import { Trans } from 'react-i18next';
import englishText from 'languages/english';
import { propertyType } from 'utils/constants';

import 'react-input-range/lib/css/index.css';

const MoreFilters = (props: any) => {
	const [activeTab, setactiveTab] = useState(propertyType.HOUSE);
	let inputRef: any = null;

	useEffect(() => {
		if (inputRef && inputRef.inputRef && (inputRef.inputRef.value === '' || !inputRef.inputRef.value)) {
			inputRef.inputRef.value = props.location;
			inputRef.inputRef.setAttribute('value', props.location);
			inputRef.inputRef.click();
			inputRef.inputRef.addEventListener('change', handleOnChange);

			return () => {
				inputRef && inputRef.inputRef && inputRef.inputRef.removeEventListener('change', handleOnChange);
			};
		}
	});

	const handleOnChange = (event: any) => {
		if (event.target.value === '') {
			props.handleAutosuggest('');
		}
	};

	const minMaxFields = [
		{
			startName: 'fromPlotApprox',
			endName: 'toPlotApprox',
			header: englishText.PLOT,
			valueMin: parseFloat(props.fromPlotApprox) || 0,
			valueMax: parseFloat(props.toPlotApprox) || 2000,
			placeholder: 'm² min.',
			placeholderTo: 'm² max.',
			min: 0,
			max: 2000
		},
		{
			startName: 'fromYearOfContruction',
			endName: 'toYearOfContruction',
			header: englishText.YEAR_OF_CONSTRUCTION,
			valueMin: parseFloat(props.fromYearOfContruction) || 1950,
			valueMax: parseFloat(props.toYearOfContruction) || 2020,
			placeholder: englishText.YEAR_MIN,
			placeholderTo: englishText.YEAR_MAX,
			min: 1950,
			max: 2025
		}
		// {
		// 	startName: 'fromAgeOfadvert',
		// 	endName: 'toAgeOfadvert',
		// 	header: englishText.ADVERT_AGE,
		// 	valueMin: props.fromAgeOfadvert || 0,
		// 	valueMax: props.toAgeOfadvert || 50,
		// 	placeholder: 'Age min.',
		// 	placeholderTo: 'Age max.',
		// 	min: 0,
		// 	max: 50
		// }
	];

	const getMaxValue = (name: string, maxValue: number) => {
		const value = props[name];
		return !value || (value && parseInt(value) === 0) || value === '' ? maxValue : value;
	};

	const applyMorefilter = () => {
		props.applyFilter(props.menuName);
	};

	return (
		<div
			className={`filter-modal more-filter ${props.show ? 'open fade-in' : 'close-model fade-out'}`}
			style={{ textAlign: 'left' }}
		>
			<div className='filter-wrapper' ref={props.setWrapperRef}>
				<div
					className='container-fluid mt-4 more-filter-container more-filter-height'
					style={{ height: '630px' }}
				>
					<div className='filter-data-container' style={{ padding: '20px', height: '95%' }}>
						{/* Proprty types section */}
						<div className='filter-data-item'>
							<h2 className='title-header pt-3 flex flex-column gap-2'>
								{getLocalizeText(englishText.TYPES_OF_BUILDING)}
								<div className='row prop-types-tab mt-3' style={{ float: 'right' }}>
									{[
										propertyType.HOUSE,
										propertyType.APT,
										propertyType.PLOT,
										propertyType.PARKING,
										propertyType.COMMERCIAL
									].map((property, index) => {
										return (
											<p
												className={`${activeTab === property && 'active'}`}
												key={index}
												onClick={() => setactiveTab(property)}
											>
												{getLocalizeText(property)}
											</p>
										);
									})}
								</div>
							</h2>

							<div className='filter-item pb-4'>
								<div className='checkbox-wrapper mt-4'>
									{props.houseTypes &&
										props.houseTypes.length > 0 &&
										props.houseTypes.map(
											(type: any, index: number) =>
												type.propTypeFor === activeTab && (
													<Form.Group
														className='d-flex align-items-center justify-content-between form-group'
														controlId='formBasicCheckbox'
														key={index}
													>
														<Form.Check className='custom-control custom-checkbox'>
															<Form.Check.Input
																id={type.label}
																name={type.label}
																checked={type.value}
																onChange={props.handleOptionchange('houseTypesArray')}
																className='custom-control-input'
															/>
															<Form.Check.Label
																className='custom-control-label'
																htmlFor={type.label}
															>
																{getLocalizeText(type.label)}
															</Form.Check.Label>
														</Form.Check>
													</Form.Group>
												)
										)}
								</div>
							</div>
						</div>
						{/* Other features ection */}
						{activeTab !== propertyType.PLOT && activeTab !== propertyType.PARKING && (
							<div className='filter-data-item'>
								<h2 className='title-header pt-3'>
									<div>Ausstattung</div>
								</h2>
								<div className='checkbox-wrapper mt-4 pb-4'>
									{props.extraFeatures &&
										props.extraFeatures.length > 0 &&
										props.extraFeatures.map((feature: any, index: number) => (
											<Form.Group
												className='d-flex align-items-center justify-content-between form-group'
												controlId='formBasicCheckbox'
												key={index}
											>
												<Form.Check className='custom-control custom-checkbox'>
													<Form.Check.Input
														id={feature.label}
														name={feature.label}
														checked={feature.value}
														onChange={props.handleOptionchange('extraFeatures')}
														className='custom-control-input'
													/>
													<Form.Check.Label
														className='custom-control-label'
														htmlFor={feature.label}
													>
														{getLocalizeText(feature.label)}
													</Form.Check.Label>
												</Form.Check>
											</Form.Group>
										))}
									{/* <Form.Group
									className='d-flex align-items-center justify-content-between form-group'
									controlId='formBasicCheckbox'
								>
									<Form.Check className='custom-control custom-checkbox'>
										<Form.Check.Input
											id={'typeAuctionForced'}
											name={'typeAuctionForced'}
											checked={props.typeAuctionForced || false}
											onChange={props.handleAuctionCheck}
											className='custom-control-input'
										/>
										<Form.Check.Label
											className='custom-control-label'
											htmlFor={'typeAuctionForced'}
										>
											{getLocalizeText(englishText.HIDE_FORECLOSURE)}
										</Form.Check.Label>
									</Form.Check>
								</Form.Group> */}
								</div>
							</div>
						)}

						{/* radius start */}
						{/* <div
              className="filter-data-item radius-section"
              style={{ boxShadow: "none" }}
            >
              <div className="filter-item pb-2">
                <h2 className="title-header pt-4">
                  {getLocalizeText(englishText.SEARCH_BY_ZIP)}
                </h2>
                <SuggestionInputSearch
                  onSubmitFunction={props.handleAutosuggest}
                  recentSearches={props.setautosuggestionList}
                  placeholder={getLocalizeText(englishText.SEARCH_LOCATION)}
                  inputPosition={"center"}
                  autocompleteOnMatch={true}
                  ref={setInputRef}
                />
              </div>
            </div> */}

						{/* Plot and construction years */}
						{minMaxFields.map((field, index) => {
							if (activeTab === propertyType.PLOT && field.header === englishText.YEAR_OF_CONSTRUCTION) {
								return;
							}
							return (
								<div className={`filter-data-item mb-4`} key={index}>
									<div className='filter-item pb-5'>
										<h2 className='title-header pt-3'>{getLocalizeText(field.header)}</h2>
										<div className='range-item d-flex align-items-center'>
											<p className='slider-p'>{getLocalizeText(field.placeholder)}</p>
											<div className='slider-div'>
												<Form.Group className={`mb-0 form-group`} controlId='formBasicRange'>
													<span
														className='range-slider-value'
														style={{
															left: `${
																field.startName === 'fromYearOfContruction'
																	? `calc(${
																			((field.valueMin - 1950) * 100) / 75
																	  }% - 14px)`
																	: `calc(${
																			(field.valueMin * 100) / field.max
																	  }% - 14px)`
															}`
														}}
													>
														{field.endName === 'radius'
															? parseFloat(`${field.valueMin} Km.`)
															: field.valueMin}
													</span>
													<InputRange
														key={field.startName}
														minValue={field.min}
														maxValue={field.max}
														value={{
															min: field.valueMin,
															max: getMaxValue(field.endName, field.max)
														}}
														onChange={props.moreHandleChange(
															field.startName,
															field.endName
														)}
													/>
													{field.endName !== 'radius' && (
														<span
															className='range-slider-value'
															style={{
																left: `${
																	field.startName === 'fromYearOfContruction'
																		? `calc(${
																				((getMaxValue(
																					field.endName,
																					field.max
																				) -
																					1950) *
																					100) /
																				75
																		  }% - 16px)`
																		: `calc(${
																				field.valueMax && field.valueMax === 0
																					? 100
																					: (field.valueMax * 100) / field.max
																		  }% - 16px)`
																} `
															}}
														>
															{getMaxValue(field.endName, field.max)}
														</span>
													)}
												</Form.Group>
											</div>
											<p className='slider-p'>{getLocalizeText(field.placeholderTo)}</p>
										</div>
									</div>
								</div>
							);
						})}

						<div className='w-100 pt-3 pb-3 text-center bottom-btn apply-btn-line'>
							<button className='button-apply' onClick={applyMorefilter} style={{ marginLeft: '100px' }}>
								<Trans i18nKey={englishText.APPLY} defaultMessage={englishText.APPLY} />
							</button>
							<p
								className='clear-btn'
								style={{ marginRight: '25px', marginLeft: '35px' }}
								onClick={() => props.clearMoreFilter()}
							>
								<Trans i18nKey={englishText.CLEAR} defaultMessage={englishText.CLEAR} />
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

MoreFilters.propTypes = {
	houseTypes: PropTypes.array.isRequired,
	extraFeatures: PropTypes.array.isRequired,
	handleOptionchange: PropTypes.func.isRequired,
	applyFilter: PropTypes.func.isRequired,
	toggleFilter: PropTypes.func.isRequired,
	setWrapperRef: PropTypes.func.isRequired,
	moreHandleChange: PropTypes.func.isRequired,
	clearMoreFilter: PropTypes.func.isRequired,
	optionChangedForMore: PropTypes.bool.isRequired,
	menuName: PropTypes.string,
	show: PropTypes.bool.isRequired,
	toPlotApprox: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	fromPlotApprox: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	fromAgeOfadvert: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	toAgeOfadvert: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	toYearOfContruction: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	fromYearOfContruction: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	// setautosuggestionList: PropTypes.array,
	location: PropTypes.string,
	typeAuctionForced: PropTypes.bool,
	handleAutosuggest: PropTypes.func,
	handleAuctionCheck: PropTypes.func
};

MoreFilters.defaultProps = { show: false, optionChangedForMore: false };

export default MoreFilters;
