import React, { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import Select, { components, StylesConfig } from 'react-select';
import Autocomplete from 'react-autocomplete';
// actions
import * as searchActions from 'actions/searchActions';
import { clearReducer } from 'actions/clearAction';

// functions & constants
import * as searchFunctions from '../utils/searchFunctions';
import * as utilFunctions from 'utils/functions';
import { extraFeatures, houseTypesArray, modalNames, sortData } from 'utils/constants';
import { setProperties } from '../utils/commonFunctions';
import { inputFieldStyle, menuFieldStyle, radiusSelectStyles, searchSelectStyles } from '../utils/searchSelectStyles';
import englishText from 'languages/english';
import HttpService from 'services/http.service';
import { API_CONFIG } from 'services/api';
// components and popups
import { INIT_FILTERS } from 'components/home/components/filterSection';
import SearchResult from '../components/searchResult';
import SortComponent from '../components/sortComponent';
import MoreFilters from '../components/moreFilters';
import SearchFilters from '../components/searchFilters';
import Register from 'components/home/components/register';
import MyProperty from 'components/newMyProperty/container/myProperty';
import { MapPinIcon } from 'components/commonComponents/icons';
import { dropdownMieten, dropdownRadius, dropdownWohnung, SEARCH_FILTERS } from 'components/home/utils/constants';
// assets
import 'react-image-gallery/styles/css/image-gallery.css';
import '../dashboard.scss';

interface IDashboardProps {
	history?: any;
	changeCity?: (city: string) => void;
	getSearchResultsAction: (payload?: any) => void;
	getSuggestedLocation: () => void;
	clearReducer: (name: string) => void;
	searchResultReducer?: any;
	profileDetail?: any;
	suggestedLocationReducer?: any;
}
interface PaginationOption {
	locationId: string;
	radius: string;
	objectType: string;
	demandType: string;
	fromPrice: string;
	toPrice: string;
	fromAreaLiving: string;
	toAreaLiving: string;
	fromNumberOfRooms: string;
	toNumberOfRooms: string;
	recordPerPage: number;
	zipcode: number;
	fromYearOfContruction: string;
	toYearOfContruction: string;
	fromPlotApprox: string;
	toPlotApprox: string;
	objectSubType: [];
	otherFeatures: [];
	sortBy: string;
	sortType: string;
}
// let setautosuggestionList: any = [];

class Dashboard extends Component<IDashboardProps> {
	state: any = {
		showSpinner: false,
		mapData: [],
		multiMapData: [],
		showModalName: '',
		...searchFunctions.newSearchState,
		...searchFunctions.paginationInitalState,
		...sortData,
		selectedSortName: 'created_at',
		selectedSortLabel: englishText.STANDARD_SORTING,
		isFullMapScreen: false,
		currentView: 'list',
		actionType: '',
		visibleModal: '',
		searchList: [],
		// locationId: '',
		typeAuctionForced: null,
		mainLocationArr: [],
		tempLocationArr: [],
		locationInputTxt: '',
		selectedCityName: '',
		showResult: false,
		demandType: '',
		advertisingShow: false,
		resetCount: 0
	};
	wrapperRef: any;
	wrapperRefNew: any;
	placesInputRef: any;

	componentDidMount() {
		const { searchResultReducer, suggestedLocationReducer } = this.props;
		this.setState({
			showSpinner: true,
			fromAreaLiving: 0,
			fromNumberOfRooms: 0,
			price: 0,
			space: 0,
			rooms: 0
		});

		this.findUserLocation();
		if (searchResultReducer.status === 'success' && searchResultReducer.data) {
			this.setFiltersAndDataDisplay();
		}

		// remove this if condition
		if (suggestedLocationReducer.status === 'success' && suggestedLocationReducer.data && this.state.showResult) {
			this.setState({
				mainLocationArr: suggestedLocationReducer.data,
				tempLocationArr: this.filterLocationData(suggestedLocationReducer.data, this.state.locationInputTxt)
			});
		} else {
			this.props.getSuggestedLocation();
		}

		document.addEventListener('mousedown', this.handleClickOutside);

		window.scrollTo(0, 0);
		if (!localStorage.getItem('token')) {
			this.updateFromLocalStorage();
		}

		// when the page reloads then all filter values are set
		const filterLocalData = localStorage.getItem('filterData');
		if (filterLocalData) {
			let filterDataValue = JSON.parse(filterLocalData);
			const {
				objectSubType = [],
				otherFeatures = [],
				sortBy,
				sortType,
				toPrice,
				toNumberOfRooms,
				toAreaLiving,
				fromPlotApprox,
				toPlotApprox,
				fromYearOfContruction,
				toYearOfContruction,
				newObjectSubType = []
			} = filterDataValue;

			const updateArrayValues = (
				typeArray:
					| {
							label: string;
							value: boolean;
							propTypeFor: string;
					  }[]
					| {
							label: string;
							value: boolean;
							countName: string;
					  }[],
				values: string[]
			) =>
				typeArray.map((item: any) => ({
					...item,
					value: values.includes(item.label)
				}));

			if ((houseTypesArray.length > 0 || newObjectSubType.length > 0) && objectSubType.length > 0) {
				const updatedHouseTypesArray = updateArrayValues(houseTypesArray, [
					...objectSubType,
					...newObjectSubType
				]);
				this.setState({ houseTypesArray: updatedHouseTypesArray, isMoreFilterActive: true });
			}

			if (extraFeatures.length > 0 && otherFeatures.length > 0) {
				const updatedExtraFeatures = updateArrayValues(extraFeatures, otherFeatures);
				this.setState({ extraFeatures: updatedExtraFeatures, isMoreFilterActive: true });
			}
			if (
				(fromPlotApprox && parseFloat(fromPlotApprox) !== 0) ||
				(toPlotApprox && parseFloat(toPlotApprox) !== 2000) ||
				(fromYearOfContruction && parseFloat(fromYearOfContruction) !== 1950) ||
				(toYearOfContruction && parseFloat(toYearOfContruction) !== 2020)
			) {
				this.setState({ isMoreFilterActive: true });
			}
			const selectedSortLabel = sortData.sortList.find((item) => item.value === sortBy)?.label;

			this.setState({
				selectedSortName: sortBy,
				selectedSortLabel,
				selectedSortType: sortType,
				price: toPrice,
				rooms: toNumberOfRooms,
				space: toAreaLiving
			});
		}
	}
	updateFromLocalStorage = (userId?: string) => {
		localStorage.removeItem('isImmobiliensuche');

		// get init data
		const userSerchData = utilFunctions.removeEmptyStrings(INIT_FILTERS);

		const filterLocalData = localStorage.getItem('filterData');
		let filter: any = { ...userSerchData };
		if (filterLocalData) {
			let filterDataValue = JSON.parse(filterLocalData);
			filter = {
				...filterDataValue
			};
			this.setState(filter);

			// setTimeout(() => {
			//     localStorage.removeItem('filterData');
			// }, 2500);
		}
		if (userId) {
			filter = { ...filter, userId: userId };
		}
		this.props.getSearchResultsAction(filter);
	};

	setFiltersAndDataDisplay = () => {
		const { searchResultReducer, clearReducer } = this.props;
		const data = searchResultReducer.data.data;

		if (data && data.length > 0) {
			if (localStorage.getItem('filterData')) {
				const filters = JSON.parse(localStorage.getItem('filterData') || '');

				filters && this.setState({ ...filters });
				let a = {};

				Object.keys(filters).forEach((key: string) => {
					if (filters[key] !== '' || (Array.isArray(filters[key]) && filters[key].length > 0)) {
						a = { ...a, [key]: filters[key] };
					}
				});
				this.setState({ ...a });
			}

			this.setPaginatedDataToDisplay(searchResultReducer.data);
		} else {
			this.setEmptyMessage();
		}
		setTimeout(() => {
			clearReducer('CLEAR_SEARCH_RESULT');
		}, 500);
	};

	componentDidUpdate(prevProps: Readonly<IDashboardProps>): void {
		const { searchResultReducer, suggestedLocationReducer, profileDetail } = this.props;

		if (
			prevProps.searchResultReducer !== searchResultReducer &&
			searchResultReducer.status === 'success' &&
			searchResultReducer.data
		) {
			// const data = searchResultReducer.data.data;

			// if (data && data.length > 0) {
			this.setFiltersAndDataDisplay();
			// } else {
			// 	this.setEmptyMessage();
			// }
		}
		if (
			prevProps.suggestedLocationReducer.status !== suggestedLocationReducer.status &&
			suggestedLocationReducer.status === 'success' &&
			suggestedLocationReducer.data
		) {
			this.findUserLocation();
			if (this.state.mainLocationArr.length === 0) {
				this.setState({
					mainLocationArr: suggestedLocationReducer.data,
					tempLocationArr: this.filterLocationData(suggestedLocationReducer.data, this.state.locationInputTxt)
				});
			}
		}

		// Fetch property list when userId is available from profile API initially
		if (!prevProps.profileDetail?.data?.userId && profileDetail?.data?.userId) {
			this.updateFromLocalStorage(profileDetail?.data?.userId);
			// this.props.getSearchResultsAction({
			//     userId: profileDetail?.data?.userId,
			// });
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	render() {
		let filterData = [
			{
				title: 'Radius',
				placeholder: '-- KM',
				value: this.state.radius,
				options: dropdownRadius,
				isMulti: false,
				style: radiusSelectStyles
			},
			{
				title: 'Was suchst Du?',
				placeholder: 'Bitte wählen',
				value: this.state.objectType,
				options: dropdownWohnung,
				isMulti: true,
				style: searchSelectStyles
			},
			{
				title: '',
				placeholder: 'Bitte wählen',
				value: this.state.demandType,
				options: dropdownMieten,
				isMulti: true,
				style: searchSelectStyles
			}
		];
		const {
			searchList,
			totalPages,
			totalRecords,
			showResultSpinner,
			showSpinner,
			currentPage,
			pageChanged,
			houseTypesArray,
			extraFeatures,
			currentValueMin,
			currentValueMax,
			emptyListError,
			optionChangedForMore,
			showModalName,
			currentView,
			isFullMapScreen,
			actionType,
			visibleModal,
			sortList,
			selectedSortType,
			selectedSortName,
			selectedSortLabel
		} = this.state;

		const windowWidth = {
			'--element-window-width': `${(window.innerWidth - 1920) / 2 - 8}px`
		};
		const CaretDownIcon = () => {
			return <i className='fa fa-caret-down dropdown-arrow ' aria-hidden='true' />;
		};

		const DropdownIndicator = (props: any) => {
			return (
				<components.DropdownIndicator {...props}>
					<CaretDownIcon />
				</components.DropdownIndicator>
			);
		};
		return (
			<div className='search-result-section'>
				<div
					className='property-result-wrapper'
					// @ts-ignore
					style={{ ...windowWidth }}
				>
					<div className='top-search-section'>
						<div className='top-search-wrapper'>
							<div className='flex justify__content--center align__items--center search-filters '>
								<p className='top-search-title'>Wo suchst Du?</p>
								<div className='user-location-wrapper'>
									<Autocomplete
										getItemValue={(item) => item.name}
										items={
											this.state.tempLocationArr.length
												? this.state.tempLocationArr
												: [{ id: -1, name: 'Keine Ergebnisse' }]
										}
										isItemSelectable={(item) => item.id !== -1}
										renderItem={this.formatResult}
										renderMenu={this.renderMenu}
										value={this.state.locationInputTxt}
										onChange={this.handleInputChange}
										onSelect={this.selectResult}
										wrapperStyle={{
											width: '100%',
											position: 'relative'
										}}
										inputProps={{
											placeholder: 'Wo? Ort, Straße, PLZ, ...',
											style: inputFieldStyle
										}}
										menuStyle={menuFieldStyle}
									/>
									{!this.state.locationInputTxt && (
										<div className='arrow-wrapper'>
											<i className='fa fa-caret-down dropdown-arrow' aria-hidden='true' />
										</div>
									)}
									{this.state.locationInputTxt && (
										<button
											type='button'
											className='close cross-wrapper'
											aria-label='Close'
											onClick={() =>
												this.setState(
													{
														locationId: '',
														radius: '',
														selectedCityName: '',
														zipcode: 0,
														locationInputTxt: ''
													},
													() => this.applyFilterSingle()
												)
											}
										>
											<span aria-hidden='true'>&times;</span>
										</button>
									)}
								</div>
								{filterData.map((filter: any, index) => {
									return (
										<div key={index} className='flex align__items--center top-search-wrapper'>
											<p className='top-search-title'>{filter.title}</p>
											<div
												title={
													filter.title === 'Radius' &&
													(!this.state.locationId ||
														this.state.locationId === '' ||
														this.state.locationInputTxt === '')
														? 'Bitte wählen Sie den Standort aus'
														: ''
												}
												className='cursor-pointer'
											>
												<Select
													components={{ DropdownIndicator }}
													className='react-select-container z-index--3'
													classNamePrefix='react-select'
													styles={
														(filter.title === 'Radius'
															? radiusSelectStyles
															: searchSelectStyles) as StylesConfig
													}
													options={filter.options}
													name={filter.placeholder}
													isSearchable={false}
													placeholder={filter.placeholder}
													value={filter.options.filter(
														(item: any) => item.value === filter.value
													)}
													isDisabled={
														filter.title === 'Radius' &&
														(!this.state.locationId || this.state.locationId === '')
													}
													onChange={this.filterValueChange}
												/>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div className={`curved-div `}>
						<div className='section-heading text-center'>
							<div className='title text-center'>
								{totalRecords} Ergebnisse in {`${this.state.selectedCityName || 'Deutschland'}`}
							</div>
							<div className='button-wrapper d-flex flex-wrap justify-content-between'>
								<div
									className={`sort-div-wrapper position-relative ${
										totalRecords === 0 ? 'pointer-event' : ''
									}`}
								>
									<SortComponent
										sortObj={{
											sortList,
											selectedSortType,
											selectedSortName,
											selectedSortLabel,
											top: '50px',
											left: '0px'
										}}
										changeSorting={this.changeSortingName}
										changeSortingType={this.changeSortingType}
										toggleSortMenu={this.toggleSortMenu}
										visibleModal={visibleModal}
										setWrapperRefForSortMenu={this.setWrapperRefForSortMenu}
									/>
								</div>
								<div className='d-flex flex-wrap position-relative'>
									{SEARCH_FILTERS.map(({ name, label, value, className }) => (
										<div className='button-item mr-2' key={name}>
											<button
												className={`button button-filter top-btn
                        						${this.getClassName(name, value)}`}
												onClick={() => this.toggleSmallFilter(name, className)}
											>
												<Trans i18nKey={label} defaultMessage={label} />
											</button>
										</div>
									))}
									<div className='button-item mr-2'>
										<button
											className={`button button-filter top-btn ${
												this.state.isMoreFilterActive ? 'activeBtn' : ''
											} ${
												visibleModal === modalNames.MORE_FILTER &&
												!this.state.isMoreFilterActive &&
												'more-btn-active'
											}`}
											onClick={this.toggleFilter}
										>
											<Trans
												i18nKey={englishText.MORE_FILTERS}
												defaultMessage={englishText.MORE_FILTERS}
											/>
										</button>
									</div>
									<div className='button-item mr-2' onClick={this.resetFilter}>
										<button
											className={`button button-filter reset-filter top-btn d-flex align-items-center`}
										>
											<i
												className={`fa fa-refresh reset-icon ${
													actionType === 'resetFilter' ? 'ani-rotate-360' : ''
												}`}
												aria-hidden='true'
											></i>

											<Trans
												i18nKey={englishText.RESET_FILTER}
												defaultMessage={englishText.RESET_FILTER}
											/>
										</button>
									</div>

									{/* for price, space and rooms filters */}
									{this.state.showSmallFilter && (
										<SearchFilters
											className={this.state.className}
											menuName={this.state.menuName}
											currentValueMax={currentValueMax}
											onSmallSliderChange={this.onSmallSliderChange}
											onSmallSliderInputChange={this.onSmallSliderInputChange}
											applyFilterSingle={this.applyFilterSingle}
											currentValueMin={currentValueMin}
											setWrapperRef={this.setWrapperRef}
											clearSmallFilter={this.clearSmallFilter}
											show={this.state.showSmallFilter}
											priceGraph={this.state.priceGraph}
											spaceGraph={this.state.spaceGraph}
											roomsGraph={this.state.roomsGraph}
										/>
									)}

									{visibleModal === modalNames.MORE_FILTER && (
										<MoreFilters
											houseTypes={houseTypesArray}
											extraFeatures={extraFeatures}
											handleOptionchange={this.handleOptionchange}
											moreHandleChange={this.moreHandleChange}
											applyFilter={this.applyFilterSingle}
											fromPlotApprox={this.state.fromPlotApprox}
											toPlotApprox={this.state.toPlotApprox}
											fromYearOfContruction={this.state.fromYearOfContruction}
											toYearOfContruction={this.state.toYearOfContruction}
											fromAgeOfadvert={this.state.fromAgeOfadvert}
											toAgeOfadvert={this.state.toAgeOfadvert}
											optionChangedForMore={optionChangedForMore}
											setWrapperRef={this.setWrapperRef}
											toggleFilter={() => this.toggleFilter(false)}
											show={visibleModal === modalNames.MORE_FILTER}
											clearMoreFilter={this.clearMoreFilter}
											// setautosuggestionList={setautosuggestionList}
											location={this.state.location}
											typeAuctionForced={this.state.typeAuctionForced}
											handleAutosuggest={this.handleAutosuggest}
											handleAuctionCheck={this.handleAuctionCheck}
											menuName={this.state.menuName}
										/>
									)}

									{localStorage.getItem('token') && (
										<button
											className='advertising-btn-wrapper'
											onClick={() =>
												this.setState({
													advertisingShow: !this.state.advertisingShow
												})
											}
										>
											+ Inserieren
										</button>
									)}
								</div>
								<div
									className='pointer'
									onClick={() => {
										this.toggleMapView(currentView === 'map' ? 'list' : 'map');
									}}
								>
									<button
										className={`btn btn-map d-flex justify-content-center ${
											currentView === 'map' ? 'active' : ''
										}`}
										disabled={totalRecords === 0 ? true : false}
									>
										<MapPinIcon />
										<p>
											<Trans i18nKey={englishText.MAP} defaultMessage={englishText.MAP} />
										</p>
									</button>
								</div>
							</div>
						</div>
					</div>
					<hr className='contact-frame'></hr>

					<SearchResult
						searchList={searchList}
						totalRecords={totalRecords}
						mapData={this.state.mapData}
						multiMapData={this.state.multiMapData}
						totalPages={totalPages}
						showSpinner={showSpinner}
						currentPage={currentPage}
						onPageChange={this.onPageChange}
						pageChanged={pageChanged}
						resetPageChange={this.resetPageChange}
						from={'search'}
						showResultSpinner={showResultSpinner}
						emptyListError={emptyListError}
						isFullMapScreen={isFullMapScreen}
						currentView={currentView}
						showBg={visibleModal === '' && showModalName === '' && !this.state.showSmallFilter}
						scrollY={0}
						onboarding={true}
						selectedCity={''}
						showBanner={false}
					/>
					<Register />
				</div>
				{this.state.advertisingShow && (
					<MyProperty closeModal={this.closeModal} resetFilter={this.resetFilter} />
				)}
			</div>
		);
	}

	filterLocationData = (locationData: [], value: string) => {
		const startingMatches = locationData.filter((location: { id: string; name: string }) =>
			location.name.toLowerCase().startsWith(value.toLowerCase())
		);

		// Then, find locations where the name contains the input but doesn't start with it
		const containingMatches = locationData.filter(
			(location: { id: string; name: string }) =>
				!location.name.toLowerCase().startsWith(value.toLowerCase()) &&
				location.name.toLowerCase().includes(value.toLowerCase())
		);

		// Combine results, starting matches first
		// Limit the results to a maximum of 10 items
		return [...startingMatches, ...containingMatches].slice(0, 15);
	};
	handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState({ locationInputTxt: e.target.value });
		const value = e.target.value.toLowerCase();
		const arr = [...this.state.mainLocationArr];

		this.setState({
			tempLocationArr: this.filterLocationData(arr as [], value)
		});
	};

	findUserLocation = () => {
		const { suggestedLocationReducer } = this.props;
		const filterLocalData = localStorage.getItem('filterData');

		if (
			suggestedLocationReducer.status === 'success' &&
			suggestedLocationReducer.data &&
			filterLocalData // Check if filterLocalData is not empty
		) {
			try {
				const filterDataValue = JSON.parse(filterLocalData);
				const { zipcode, locationId } = filterDataValue;

				if (!locationId) {
					return;
				}

				const hasPincode = (name: string) => /\d{4,}/.test(name);

				const suggestedLocations = suggestedLocationReducer.data;
				let locationItem = null;

				if (zipcode) {
					locationItem = suggestedLocations.find(
						(location: any) => location.id === locationId && location.name.includes(zipcode)
					);
				} else {
					locationItem = suggestedLocations.find(
						(location: any) => location.id === locationId && !hasPincode(location.name)
					);
				}

				if (locationItem) {
					const [cityName] = locationItem.name.split(','); // Get the first part as city name
					this.setState({
						locationInputTxt: locationItem.name,
						selectedCityName: cityName,
						showResult: true,
						tempLocationArr: this.filterLocationData(suggestedLocations, locationItem.name)
					});
				} else {
					console.warn('No matching location found');
				}
			} catch (error) {
				console.error('Error parsing JSON:', error);
			}
		}
	};

	closeModal = () => {
		this.setState({
			advertisingShow: false
		});
	};

	formatResult = (item: { id: string; name: string }, isHighlighted: boolean) => {
		return (
			<div className='result-wrapper' style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
				<span className='result-span'>{item.name}</span>
			</div>
		);
	};
	renderMenu = (items: React.ReactNode, value: string, style: React.CSSProperties) => {
		return (
			<div style={{ ...style, ...menuFieldStyle }}>
				<div className='location-result-wrapper'>{items}</div>
			</div>
		);
	};
	selectResult = (value: string, item: { id: string; name: string }) => {
		const zipcode = Number(value.match(/\d+/g));

		this.setState(
			{
				locationId: item.id,
				selectedCityName: value.split(',')[0],
				zipcode,
				locationInputTxt: value
			},
			() => this.applyFilterSingle()
		);
	};

	changeSortingName = (sortObj: any) => {
		if (this.state.selectedSortName !== sortObj.value) {
			const { selectedSortType } = this.state;

			const sortObjNew = {
				sortType: selectedSortType,
				sortBy: sortObj.value,
				currentPage: 1
			};

			this.setState({
				selectedSortName: sortObj.value,
				selectedSortLabel: sortObj.label,
				showResultSpinner: true,
				visibleModal: '',
				pageChanged: true
			});
			this.applyFilterSingle(sortObjNew);
		}
	};

	// change asc/desc
	changeSortingType = (type: string) => {
		if (this.state.selectedSortType !== type) {
			const { selectedSortName } = this.state;

			const sortObj = {
				sortType: type,
				sortBy: selectedSortName
			};

			this.setState({
				selectedSortType: type,
				showResultSpinner: true,
				visibleModal: '',
				currentPage: 1,
				pageChanged: true
			});

			this.applyFilterSingle(sortObj);
		}
	};

	toggleSortMenu = (value: any) => {
		!value && utilFunctions.disableScrolling(value);
		this.setState({
			visibleModal: value ? modalNames.SORT_MENU : '',
			showSmallFilter: false,
			optionChangedForMore: false,
			showModalName: ''
		});
	};

	// to close filters on clicking outside
	handleClickOutside = (event: any) => {
		const { showSmallFilter, visibleModal, showModalName } = this.state;
		if (
			(showModalName !== '' || visibleModal !== '') &&
			this.wrapperRefNew &&
			!this.wrapperRefNew.contains(event.target)
		) {
			try {
				this.setState({
					showModalName: '',
					visibleModal: ''
				});
			} catch (error) {
				this.setState({ showModalName: '', visibleModal: '' });
			}
		}

		if (
			(showSmallFilter || visibleModal === modalNames.MORE_FILTER || visibleModal !== '') &&
			this.wrapperRef &&
			!this.wrapperRef.contains(event.target)
		) {
			if (showSmallFilter) {
				this.toggleSmallFilter(this.state.menuName);
				this.setState({ menuName: '' });
			} else if (visibleModal === modalNames.MORE_FILTER) {
				this.toggleFilter(false);
			} else {
				this.setState({ visibleModal: '' });
			}

			localStorage.removeItem('topOpen');
		}
	};

	handleAutosuggest = (text: string) => {
		this.setState({ location: text });
	};

	handleAuctionCheck = () => {
		this.setState({ typeAuctionForced: !this.state.typeAuctionForced });
	};
	filterValueChange = (event: any) => {
		this.setState({ [event.id]: event.value });

		if (event.id === 'radius') {
			this.setState({ radius: event.value }, () => this.applyFilterSingle());
		} else if (event.id === 'demandType') {
			this.setState({ demandType: event.value }, () => this.applyFilterSingle());
		} else {
			this.setState({ objectType: event.value }, () => this.applyFilterSingle());
		}
	};
	// getAutosuggestionList = () => {
	// 	HttpService.get(`v1/object/locationsOptimize`, {})
	// 		.then((response) => {
	// 			setautosuggestionList = response.data;
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	getSearchResultsPagination = (page: number, filterValue: PaginationOption) => {
		const removeEmptyFailed = utilFunctions.removeEmptyStrings(filterValue);
		const {
			locationId,
			radius,
			objectType,
			demandType,
			fromPrice,
			toPrice,
			fromAreaLiving,
			toAreaLiving,
			fromNumberOfRooms,
			toNumberOfRooms,
			recordPerPage,
			zipcode,
			fromYearOfContruction,
			toYearOfContruction,
			fromPlotApprox,
			toPlotApprox,
			objectSubType,
			otherFeatures,
			sortBy,
			sortType
		} = removeEmptyFailed;
		HttpService.post(API_CONFIG.path.objects, {
			currentPage: page,
			locationId,
			radius,
			objectType,
			demandType,
			fromPrice,
			toPrice,
			fromAreaLiving,
			toAreaLiving,
			fromNumberOfRooms,
			toNumberOfRooms,
			recordPerPage,
			zipcode,
			fromYearOfContruction,
			toYearOfContruction,
			fromPlotApprox,
			toPlotApprox,
			objectSubType,
			otherFeatures,
			sortBy,
			sortType
		})
			.then((response) => {
				this.setsearchResultData(response);
			})
			.catch(() => {
				this.setEmptyMessage();
			});
	};

	setsearchResultData = (response: any) => {
		if (!response.data || (response.data.data && response.data.data.length === 0)) {
			this.setEmptyMessage();
		} else {
			this.setPaginatedDataToDisplay(response.data);
		}
	};

	// For toggling between map and list
	toggleMapView = (value?: string) => {
		const { isFullMapScreen, currentView } = this.state;
		window.scrollTo(0, 170);

		if (currentView !== value) {
			this.setState({
				isFullMapScreen: !isFullMapScreen,
				currentView: value
			});
		}
	};

	// When no results are found
	setEmptyMessage = () => {
		this.setState({
			emptyListError: englishText.NO_RESULT_FOUND_TRY_OTHER,
			showSpinner: false,
			searchList: [],
			mapData: [],
			multiMapData: [],
			totalRecords: 0,
			totalRecordsFormatted: 0
		});
		applyClicked = false;
	};

	// called once search result arrives from API
	setPaginatedDataToDisplay = (searchResponse: any) => {
		const paginationData = {
			currentPage: searchResponse.currentPage,
			totalRecords: searchResponse.total,
			totalPages: searchResponse.totalPages,
			totalRecordsFormatted: searchFunctions.priceFormat(searchResponse.total)
		};

		const combinedResult = setProperties(searchResponse.data, []);

		this.setState({
			searchList: combinedResult.list,
			showSpinner: false,
			showResultSpinner: false,
			emptyListError: '',
			mapData: combinedResult.mapResult ? combinedResult.mapResult.singlePins : [],
			multiMapData: combinedResult.mapResult ? combinedResult.mapResult.newArray : [],
			...paginationData
		});
		window.scrollTo(0, 0);
		applyClicked = false;

		this.getGrpahValues();
	};

	// Get graph data to preload all filter's graph
	getGrpahValues = async () => {
		HttpService.get(`${API_CONFIG.path.graph}/all`, {})
			.then((response) => {
				const data = response.data;
				this.setState({
					priceGraph: data.price.array,
					roomsGraph: data.rooms.array,
					spaceGraph: data.space.array
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// pagination event
	onPageChange = (page: number) => {
		const pageNumber = page + 1;
		this.setState({ showResultSpinner: true, pageChanged: true, visibleModal: '', showModalName: '' });
		this.getSearchResultsPagination(pageNumber, this.state);
	};

	//used in map change detect
	resetPageChange = () => {
		this.setState({ pageChanged: false });
	};

	// For more price
	toggleFilter = (value?: any) => {
		const { visibleModal } = this.state;
		if (this.state.showSmallFilter) {
			const a = localStorage.getItem('initialStateArray');
			var retrievedObject = a ? JSON.parse(a) : null;
			let state = searchFunctions.getStateName(retrievedObject.menuName);
			this.setState({
				[retrievedObject.menuName]: retrievedObject.value,
				[state]: retrievedObject.valueMin
			});
		}
		this.saveAndRestoreStateValues(value);

		if (value && visibleModal === modalNames.MORE_FILTER) {
			this.setState({
				optionChangedForMore: false,
				visibleModal: ''
			});
			localStorage.removeItem('initialStateArray');
		} else {
			if (!value && visibleModal === modalNames.MORE_FILTER) {
				this.setState({ visibleModal: '' });
			} else {
				this.setState({
					visibleModal: modalNames.MORE_FILTER,
					emptyListError: '',
					showSmallFilter: false,
					showModalName: '',
					optionChangedForMore: false
				});
			}
		}
	};

	// Filters open/close handling for price, space and rooms
	toggleSmallFilter = (value: string, className?: string) => {
		const { showSmallFilter, visibleModal } = this.state;
		let filterStartValue = searchFunctions.getStateName(value);
		let filterEndValue = searchFunctions.getStateNameTo(value);

		let openState = this.state.showSmallFilter;

		if ((value === this.state.menuName && openState) || (openState && value !== this.state.menuName)) {
			if (!applyClicked) {
				const a = localStorage.getItem('initialStateArray');
				var retrievedObject = a ? JSON.parse(a) : null;
				let state = searchFunctions.getStateName(retrievedObject.menuName);
				let stateTo = searchFunctions.getStateNameTo(retrievedObject.menuName);
				this.setState({
					[retrievedObject.menuName]: retrievedObject.value,
					[state]: retrievedObject.valueMin,
					[stateTo]: retrievedObject.valueMax
				});
			}
			localStorage.removeItem('initialStateArray');
		}

		if ((openState && value !== this.state.menuName) || !openState) {
			const result = utilFunctions.getSinglefilterParameters(value);

			let currentValueMax =
				parseFloat(this.state[value]) === 0 || parseFloat(this.state[filterEndValue]) === 0
					? value === 'rooms'
						? 8.1
						: result.maxValue + result.step
					: parseFloat(this.state[filterEndValue]);

			this.setState({
				className,
				menuName: value,
				currentValueMax,
				currentValueMin:
					filterStartValue === 'fromNumberOfRooms' && parseFloat(this.state[filterStartValue]) === 0
						? 1
						: parseFloat(this.state[filterStartValue])
			});

			const initialState = {
				menuName: value,
				value: this.state[value],
				valueMin: this.state[filterStartValue],
				valueMax: this.state[filterEndValue]
			};

			localStorage.setItem('initialStateArray', JSON.stringify(initialState));
		}

		if (visibleModal === modalNames.MORE_FILTER) {
			this.saveAndRestoreStateValues(false);
			this.setState({ visibleModal: '', optionChangedForMore: false });
		}

		if (!showSmallFilter || (showSmallFilter && value !== this.state.menuName)) {
			this.setState({
				showSmallFilter: true,
				showModalName: '',
				visibleModal: ''
			});
		} else {
			this.setState({ showSmallFilter: false, menuName: '' });
		}
	};

	// For more filters checkbox/radios/inputs
	handleOptionchange = (key: string) => (event: any) => {
		const { name: selecteValue, checked, type, value } = event.target;

		if (type === 'checkbox') {
			const object = this.state[key];
			const index = object.findIndex((i: any) => i.label === selecteValue);
			object[index].value = checked;
			this.setState({ [key]: object });
		}

		if (type === 'radio') {
			this.setState({ [key]: selecteValue });
		}
		if (type === 'number') {
			if (key.includes('Year')) {
				value.length < 5 && this.setState({ [key]: value });
			} else {
				this.setState({ [key]: value });
			}
		}
		if (!this.state.optionChangedForMore) {
			this.setState({ optionChangedForMore: true });
		}
	};

	// on change event for more filte sliders
	moreHandleChange = (fromValue: string, toValue: string) => (event: any) => {
		this.setState({
			[fromValue]: event.min,
			[toValue]: event.max,
			optionChangedForMore: true
		});
	};

	// Called when filters are applied
	applyFilterSingle = (sortObj?: any) => {
		let isMoreFilterActive = false;

		!this.state.pageChanged &&
			this.setState({
				pageChanged: true,
				mapData: []
			});
		let obj;

		({ obj, isMoreFilterActive } = searchFunctions.createApiObject(this.state, isMoreFilterActive, sortObj));
		this.props.getSearchResultsAction({
			...obj,
			userId: this.props.profileDetail?.data?.userId
		});
		console.log(obj);

		localStorage.setItem('filterData', JSON.stringify(obj));
		this.setState({
			showSmallFilter: false,
			visibleModal: '',
			optionChangedForMore: false,
			showSpinner: true,
			isMoreFilterActive,
			menuName: ''
		});
		applyClicked = true;
		window.scrollTo(0, 0);
	};

	//Clear price | space | room filters
	clearSmallFilter = () => {
		const { menuName, isFullMapScreen } = this.state;

		let stateName = searchFunctions.getStateName(menuName);
		const result = utilFunctions.getSinglefilterParameters(menuName);

		const maxValue = menuName === 'rooms' ? result.maxValue + 0.1 : result.maxValue + result.step;

		const filterValue = {
			currentValueMin: '0',
			[stateName]: '0',
			[menuName]: '0',
			currentValueMax: maxValue,
			showSpinner: true,
			showSmallFilter: false
		};
		localStorage.setItem('filterData', JSON.stringify(filterValue));
		this.setState(filterValue);
		isFullMapScreen && this.toggleMapView();

		setTimeout(() => {
			this.applyFilterSingle();
		}, 2000);
	};

	// clear more filetrs data
	clearMoreFilter = () => {
		const resetObject = searchFunctions.resetStateObject(true, true);
		this.setState({ ...resetObject, visibleModal: '' });
		this.state.isFullMapScreen && this.toggleMapView();

		setTimeout(() => {
			this.applyFilterSingle();
		}, 2000);
	};

	// Reset filter click
	resetFilter = () => {
		localStorage.removeItem('filterData');
		this.setState({
			pageChanged: true,
			mapData: [],
			actionType: 'resetFilter',
			selectedCityName: ''
		});

		if (this.placesInputRef) {
			this.placesInputRef.value = '';
		}

		this.props.getSearchResultsAction({
			currentPage: 1,
			recordPerPage: searchActions.recordPerPage,
			reset: true,
			userId: this.props.profileDetail?.data?.userId
		});

		const resetObject = searchFunctions.resetStateObject(false, true);
		this.setState({ ...resetObject });
		this.state.isFullMapScreen && this.toggleMapView();

		setTimeout(
			() =>
				this.setState({
					actionType: '',
					locationInputTxt: '',
					resetCount: this.state.resetCount + 1,
					radius: ''
				}),
			1000
		);
		window.scrollTo(0, 0);
	};

	// To save and set back the values when filters are not applied any values were changed
	saveAndRestoreStateValues = (value: any) => {
		if (value && this.state.visibleModal !== modalNames.MORE_FILTER) {
			const {
				houseTypesArray,
				newObjectSubType,
				extraFeatures,
				internet,
				fromPlotApprox,
				toPlotApprox,
				fromYearOfContruction,
				toYearOfContruction,
				toAgeOfadvert,
				fromAgeOfadvert,
				location,
				typeAuctionForced
			} = this.state;

			const initialStateArray = {
				houseTypesArray,
				newObjectSubType,
				extraFeatures,
				internet,
				fromPlotApprox: fromPlotApprox || '',
				toPlotApprox: toPlotApprox || '',
				fromYearOfContruction: fromYearOfContruction || '',
				toYearOfContruction: toYearOfContruction || '',
				toAgeOfadvert: toAgeOfadvert || '',
				fromAgeOfadvert: fromAgeOfadvert || '',
				location: location || '',
				typeAuctionForced: typeAuctionForced
			};
			localStorage.setItem('initialStateArray', JSON.stringify(initialStateArray));
		} else {
			if (!applyClicked) {
				const a = localStorage.getItem('initialStateArray');
				var retrievedObject = a ? JSON.parse(a) : null;

				this.setState({ ...retrievedObject });
				localStorage.removeItem('initialStateArray');
			}
		}
	};

	// for price, space and rooms slider
	onSmallSliderChange = (value: any) => {
		const { menuName } = this.state;
		let stateName = searchFunctions.getStateName(menuName);

		this.setState({
			[menuName]: value.values[1],
			currentValueMax: value.values[1],
			currentValueMin: value.values[0],
			[stateName]: value.values[0]
		});
	};

	// for price, space and rooms input boxes
	onSmallSliderInputChange = (current: string) => (event: any) => {
		const field = event.target.name;
		const fieldValue = event.target.value;

		const result = utilFunctions.getSinglefilterParameters(field);
		if (
			(current === 'currentValueMin' && parseInt(fieldValue) > this.state.currentValueMax) ||
			parseInt(fieldValue) > result.maxValue
		) {
			return;
		} else if (current === 'currentValueMax' && parseInt(fieldValue) < this.state.currentValueMin) {
			return;
		} else {
			if (current === 'currentValueMin') {
				let stateName = searchFunctions.getStateName(field);
				this.setState({
					[stateName]: fieldValue,
					[current]: fieldValue
				});
			} else {
				this.setState({
					[field]: fieldValue,
					[current]: fieldValue
				});
			}
		}
	};

	// remove this three methods and directly assign Ref
	setWrapperRef = (node: any) => {
		this.wrapperRef = node;
	};

	setWrapperRefForSortMenu = (node: any) => {
		this.wrapperRef = node;
	};

	setRefForLocation = (node: any) => {
		this.placesInputRef = node;
	};

	// To show active | hover states for filter buttons
	getClassName = (name: string, fromstate: string) => {
		let value = 0;
		let className = '';
		if (name === 'rooms') {
			value = 0;
		}

		let active = false;

		if (this.state.menuName === name) {
			const data = localStorage.getItem('initialStateArray');

			var a = data ? JSON.parse(data) : null;
			active = (a.value && parseInt(a.valueMin) !== 0) || (a.value && parseInt(a.value) !== value);
		} else {
			let stateTo = searchFunctions.getStateNameTo(name);
			active =
				(this.state[fromstate] && parseInt(this.state[fromstate]) !== 0) ||
				(this.state[stateTo] && parseInt(this.state[stateTo]) !== value);
		}

		if (active) {
			className = 'activeBtn';
		} else {
			if (this.state.menuName === name) {
				className = 'top-btn-active';
			}
		}

		return className;
	};

	// Email verify popup toggle
	toggleMailPopup = (value: boolean) => {
		const { showMailPopup } = this.state;
		const status = value || !showMailPopup;
		this.setState({ showMailPopup: status });
		utilFunctions.disableScrolling(status);
	};
}

let applyClicked = false;

const mapStateToProps = (state: any) => ({
	searchResultReducer: state.searchResultReducer,
	suggestedLocationReducer: state.suggestedLocationReducer,
	profileDetail: state.profileDetailReducer
});

const mapDispatchToProps = (dispatch: any) => ({
	getSearchResultsAction: (payload?: any) => {
		dispatch(searchActions.getSearchResultsAction(payload));
	},
	getSuggestedLocation: () => {
		dispatch(searchActions.getSuggestedLocation());
	},
	clearReducer: (name: string) => {
		dispatch(clearReducer(name));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
