import { propertyOption } from 'components/newMyProperty/utils/constants';
import { propertyOptions } from 'utils';

export const FILTERS = [
	{
		name: 'Objekt',
		value: 'objectType',
		options: [
			{ label: propertyOption.apartment, value: propertyOption.apartment, isChecked: false },
			{ label: propertyOption.house, value: propertyOption.house, isChecked: false },
			{ label: propertyOption.land, value: propertyOption.land, isChecked: false },
			{ label: propertyOption.commercial, value: propertyOption.commercial, isChecked: false },
			{ label: propertyOption.parking, value: propertyOption.parking, isChecked: false }
		]
	},
	{
		name: 'Objektart',
		value: 'marketingType',
		options: [
			{ label: 'zu verkaufen', value: propertyOptions.SELL, isChecked: false },
			{ label: 'zu vermieten', value: propertyOptions.RENT, isChecked: false }
		]
	},
	{
		name: 'Status',
		value: 'status',
		options: [
			{ label: 'Aktiv', value: 'Active', isChecked: false },
			{ label: 'Inaktiv', value: 'Inactive', isChecked: false }
		]
	}
];

export const sortList = [
	{
		label: 'Datum der Änderung',
		value: 'updated_at'
	},
	{
		label: 'Kaufpreis',
		value: 'price'
	},
	{
		label: 'Price per m2',
		value: 'pricePerSQM'
	},
	{
		label: 'Fläche',
		value: 'livingSpace'
	},
	{
		label: 'Räume',
		value: 'rooms'
	}
];
