import React, { FC } from 'react';
import Select, { StylesConfig, components, GroupBase, DropdownIndicatorProps } from 'react-select';

import { FormDropdownIcon } from '../icons';
import { errorFormSelectStyles, formSelectStyles } from './utils/constants';

import { FormComponentProps } from 'components/profile/utils/form.interface';

const InputSelect: FC<FormComponentProps & any> = (props) => {
	const { label, isSearchable = false, asterisk = false, error, options = [], disable, ...rest } = props;

	const DropdownIndicator: React.FC<DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>> = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				<FormDropdownIcon />
			</components.DropdownIndicator>
		);
	};
	console.log(disable);

	return (
		<div className='input-select-div'>
			<div className='flex align__items--center'>
				{label && <label className='form-label'>{label}</label>}
				{asterisk && <span className='form-asterisk'>&nbsp;*</span>}
			</div>
			<div className={`flex flex--column ${disable ? 'not-allowed' : ''}`}>
				<Select
					components={{ DropdownIndicator }}
					className='form-select z-index--3 '
					classNamePrefix='react-select'
					options={options}
					styles={(error ? errorFormSelectStyles : formSelectStyles) as StylesConfig}
					isSearchable={isSearchable}
					{...rest}
					isDisabled={disable}
				/>
				{error && <span className='error-message'>{error.message}</span>}
			</div>
		</div>
	);
};

export default InputSelect;
