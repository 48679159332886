import React from 'react';
import { ApartmentIcon, CommercialIcon, HouseIcon, ParkingIcon, PropertyIcon } from 'components/commonComponents/icons';

export const requiredFields = [
	'objectTypeSub',
	'newObjectSubType',
	'adrRaw',
	'adrCity',
	'adrPostalCode',
	'adrCountry',
	'areaLiving',
	'areaPlot',
	'noOfRooms',
	'constructionYear',
	'marketingType',
	'totalRent',
	'depositCost'
];

export const apartmentOptions = {
	ATTIC_APARTMENT: 'Dachgeschosswohnung',
	MAISONETTE_APARTMENT: 'Maisonette-Wohnung',
	LOFT_STUDIO_STUDIO: 'Loft, Studio, Atelier',
	PENTHOUSE: 'Penthouse',
	TERRACE_APARTMENT: 'Terassenwohnung',
	APARTMENT: 'Etagenwohnung',
	GROUND_FLOOR_APARTMENT: 'Erdgeschosswohnung',
	BASEMENT_APARTMENT: 'Souterrainwohnung'
};

export const houseOptions = {
	TOWN_HOUSE: 'Reihenhaus',
	DETACHED_HOUSE: 'Einfamilienhaus',
	TWO_FAMILY_HOUSE: 'Zweifamilienhaus',
	SEMI_DETACHED_HOUSE: 'Doppelhaushälfte',
	APARTMENT_BUILDING: 'Mehrfamilienhaus',
	TOWNHOUSE: 'Stadthaus',
	BUNGALOW: 'Bungalow',
	VILLA: 'Villa'
};

export const commercialOptions = {
	OFFICE: 'Büro, Praxis, Raum',
	RETAIL: 'Einzelhandel',
	GUEST_TRADE: 'Gast Gewerbe',
	WAREHOUSE: 'Halle, Lager, Produktion',
	AGRICULTURE: 'Land und Forstwirtschaft',
	LEISURE_PROPERTY: 'Freizeit Immobilie Gerwerblich',
	INVESTMENT: 'Zinshaus, Rendite Objekt'
};

export const parkingOptions = {
	PARKING_GARAGE: 'Parkhaus',
	GARAGE: 'Garage',
	PARKING_AREA: 'Parkplatz'
	// GAS_STATION: 'Tankstelle',
	// MISCELLANEOUS: 'Sonstiges'
};

export const landOptions = {
	RESIDENTIAL_PROPERTY: 'Wohngrundstück',
	COMMERCIAL_PROPERTY: 'Gewerbegrundstück',
	MIXED_PROPERTY: 'Gemischtes Grundstück',
	FOREST: 'Forst',
	AGRICULTURE: 'Landwirtschaft',
	GRASSLAND: 'Grünland'
};

const errorMessage = 'Bitte füllen Sie das Feld aus';
const validInputMessage = 'Bitte eine gültige Eingabe machen';

export const positiveNumberStringRegex = /^[a-zA-Z0-9\säöüÄÖÜßẞ]+$/;
export const addressFieldRegex = /^[0-9A-Za-z\s,'äöüÄÖÜßẞ-]+$/;
export const textFieldRegex = /^[-_\sa-zA-ZäöüÄÖÜßẞ]+$/;
export const postalCodeRegex = /^[0-9]{1,6}$/;
export const numberFieldRegex = /^(0|[1-9]\d*|\d*\.\d+)$/;
export const numberRange500Regex = /^(?:[1-9][0-9]{0,2}(?:\.[5])?|499(?:\.5)?)$/;
export const yearRangeRegex = /^(?:1[89]\d\d|2[0-9]\d\d|3[0-9]\d\d|4000)$/;
export const percentFieldRegex = /^(?:0|[1-9]?\d(?:\.\d{1,2})?|100)$/;
export const emailFieldRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-ZäöüÄÖÜßẞ]{2,15}$/;
export const nameFieldRegex = /^[a-zA-ZäöüÄÖÜßẞ\s_-]{1,20}$/;
export const privacyPolicyRegex = /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm;
/* eslint-enable */

export const myPropertyRules: { [key: string]: any } = {
	objectTypeSub: {
		required: { value: true, message: errorMessage }
	},
	newObjectSubType: {
		required: { value: true, message: errorMessage }
	},
	typePropertyUse: {
		required: { value: true, message: errorMessage }
	},
	adrRaw: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: addressFieldRegex,
			message: validInputMessage
		}
	},
	adrCity: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: textFieldRegex,
			message: validInputMessage
		}
	},
	adrPostalCode: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: postalCodeRegex,
			message: validInputMessage
		}
	},
	adrCountry: {
		required: { value: true, message: errorMessage }
	},
	showAddress: {
		required: { value: true, message: errorMessage }
	},
	areaLiving: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: numberFieldRegex,
			message: validInputMessage
		}
	},
	areaPlot: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: numberFieldRegex,
			message: validInputMessage
		}
	},
	noOfRooms: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: numberRange500Regex,
			message: validInputMessage
		}
	},
	constructionYear: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: yearRangeRegex,
			message: validInputMessage
		}
	},
	marketingType: {
		required: { value: true, message: errorMessage }
	},
	totalRent: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: numberFieldRegex,
			message: validInputMessage
		}
	},
	depositCost: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: numberFieldRegex,
			message: validInputMessage
		}
	},
	costsCommissionPercent: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: percentFieldRegex,
			message: 'Zeichenbeschränkung überschritten'
		}
	},
	costsCommissionAmount: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: numberFieldRegex,
			message: 'Zeichenbeschränkung überschritten'
		}
	}
};

export const wohnungAccommodationOption = [
	{
		id: 'Dachgeschosswohnung',
		value: apartmentOptions.ATTIC_APARTMENT,
		label: apartmentOptions.ATTIC_APARTMENT
	},
	{
		id: 'Maisonette-Wohnung',
		value: apartmentOptions.MAISONETTE_APARTMENT,
		label: apartmentOptions.MAISONETTE_APARTMENT
	},
	{
		id: 'Loft, Studio, Atelier',
		value: apartmentOptions.LOFT_STUDIO_STUDIO,
		label: apartmentOptions.LOFT_STUDIO_STUDIO
	},
	{
		id: 'Penthouse',
		value: apartmentOptions.PENTHOUSE,
		label: apartmentOptions.PENTHOUSE
	},
	{
		id: 'Terassenwohnung',
		value: apartmentOptions.TERRACE_APARTMENT,
		label: apartmentOptions.TERRACE_APARTMENT
	},
	{
		id: 'Etagenwohnung',
		value: apartmentOptions.APARTMENT,
		label: apartmentOptions.APARTMENT
	},
	{
		id: 'Erdgeschosswohnung',
		value: apartmentOptions.GROUND_FLOOR_APARTMENT,
		label: apartmentOptions.GROUND_FLOOR_APARTMENT
	},
	{
		id: 'Souterrainwohnung',
		value: apartmentOptions.BASEMENT_APARTMENT,
		label: apartmentOptions.BASEMENT_APARTMENT
	}
];

export const hausAccommodationOption = [
	{
		id: 'Reihenhaus',
		value: houseOptions.TOWN_HOUSE,
		label: houseOptions.TOWN_HOUSE
	},
	{
		id: 'Einfamilienhaus',
		value: houseOptions.DETACHED_HOUSE,
		label: houseOptions.DETACHED_HOUSE
	},
	{
		id: 'Zweifamilienhaus',
		value: houseOptions.TWO_FAMILY_HOUSE,
		label: houseOptions.TWO_FAMILY_HOUSE
	},
	{
		id: 'Doppelhaushälfte',
		value: houseOptions.SEMI_DETACHED_HOUSE,
		label: houseOptions.SEMI_DETACHED_HOUSE
	},
	{
		id: 'Mehrfamilienhaus',
		value: houseOptions.APARTMENT_BUILDING,
		label: houseOptions.APARTMENT_BUILDING
	},
	{
		id: 'Stadthaus',
		value: houseOptions.TOWNHOUSE,
		label: houseOptions.TOWNHOUSE
	},
	{
		id: 'Bungalow',
		value: houseOptions.BUNGALOW,
		label: houseOptions.BUNGALOW
	},
	{
		id: 'Villa',
		value: houseOptions.VILLA,
		label: houseOptions.VILLA
	}
];

export enum GewerbeImmobilie {
	OFFICE = 'Büro',
	RETAIL = 'Einzelhandel',
	GUEST_TRADE = 'GastGewerbe',
	WAREHOUSE = 'Lager',
	AGRICULTURE = 'Forstwirtschaft',
	LEISURE_PROPERTY = 'Freizeit',
	INVESTMENT = 'Rendite'
}

export const GewerbeImmobilieOption = [
	{ id: GewerbeImmobilie.OFFICE, label: 'Büro, Praxis, Raum', value: 'Büro, Praxis, Raum' },
	{
		id: GewerbeImmobilie.RETAIL,
		label: 'Einzelhandel',
		value: 'Einzelhandel'
	},
	{ id: GewerbeImmobilie.GUEST_TRADE, label: 'Gast Gewerbe', value: 'Gast Gewerbe' },
	{ id: GewerbeImmobilie.WAREHOUSE, label: 'Halle, Lager, Produktion', value: 'Halle, Lager, Produktion' },
	{
		id: GewerbeImmobilie.AGRICULTURE,
		label: 'Land und Forstwirtschaft',
		value: 'Land und Forstwirtschaft'
	},
	{
		id: GewerbeImmobilie.LEISURE_PROPERTY,
		label: 'Freizeit Immobilie Gerwerblich',
		value: 'Freizeit Immobilie Gerwerblich'
	},
	{ id: GewerbeImmobilie.INVESTMENT, label: 'Zinshaus, Rendite Objekt', value: 'Zinshaus, Rendite Objekt' }
];
export const GewerbeImmobilieSubOption = {
	[GewerbeImmobilie.OFFICE]: [
		{ id: 'Bürofläche', label: 'Bürofläche', value: 'Bürofläche' },
		{ id: 'Bürohaus', label: 'Bürohaus', value: 'Bürohaus' },
		{ id: 'Bürozentrum', label: 'Bürozentrum', value: 'Bürozentrum' },
		{ id: 'Loft, Atelier', label: 'Loft, Atelier', value: 'Loft, Atelier' },
		{ id: 'Praxis', label: 'Praxis', value: 'Praxis' },
		{ id: 'Praxisfläche', label: 'Praxisfläche', value: 'Praxisfläche' },
		{ id: 'Praxishaus', label: 'Praxishaus', value: 'Praxishaus' },
		{ id: 'Ausstellungsfläche', label: 'Ausstellungsfläche', value: 'Ausstellungsfläche' }
	],
	[GewerbeImmobilie.RETAIL]: [
		{ id: 'Ladenlokal', label: 'Ladenlokal', value: 'Ladenlokal' },
		{ id: 'Einzelhandelsladen', label: 'Einzelhandelsladen', value: 'Einzelhandelsladen' },
		{ id: 'Verbrauchermarkt', label: 'Verbrauchermarkt', value: 'Verbrauchermarkt' },
		{ id: 'Einkaufszentrum', label: 'Einkaufszentrum', value: 'Einkaufszentrum' },
		{ id: 'Kaufhaus', label: 'Kaufhaus', value: 'Kaufhaus' },
		{ id: 'Factory, Outlet', label: 'Factory, Outlet', value: 'Factory, Outlet' },
		{ id: 'Kiosk', label: 'Kiosk', value: 'Kiosk' },
		{ id: 'Verkaufsfläche', label: 'Verkaufsfläche', value: 'Verkaufsfläche' },
		{ id: 'Ausstellungsfläche', label: 'Ausstellungsfläche', value: 'Ausstellungsfläche' }
	],
	[GewerbeImmobilie.GUEST_TRADE]: [
		{ id: 'Ladenlokal', label: 'Ladenlokal', value: 'Ladenlokal' },
		{ id: 'Einzelhandelsladen', label: 'Einzelhandelsladen', value: 'Einzelhandelsladen' },
		{ id: 'Verbrauchermarkt', label: 'Verbrauchermarkt', value: 'Verbrauchermarkt' },
		{ id: 'Einkaufszentrum', label: 'Einkaufszentrum', value: 'Einkaufszentrum' },
		{ id: 'Kaufhaus', label: 'Kaufhaus', value: 'Kaufhaus' },
		{ id: 'Factory, Outlet', label: 'Factory, Outlet', value: 'Factory, Outlet' },
		{ id: 'Kiosk', label: 'Kiosk', value: 'Kiosk' },
		{ id: 'Verkaufsfläche', label: 'Verkaufsfläche', value: 'Verkaufsfläche' },
		{ id: 'Ausstellungsfläche', label: 'Ausstellungsfläche', value: 'Ausstellungsfläche' }
	],
	[GewerbeImmobilie.WAREHOUSE]: [
		{ id: 'Halle', label: 'Halle', value: 'Halle' },
		{ id: 'Lager', label: 'Lager', value: 'Lager' },
		{ id: 'Lagerfläche', label: 'Lagerfläche', value: 'Lagerfläche' },
		{ id: 'Lager mit Freifläche', label: 'Lager mit Freifläche', value: 'Lager mit Freifläche' },
		{ id: 'Produktion', label: 'Produktion', value: 'Produktion' },
		{ id: 'Werkstatt', label: 'Werkstatt', value: 'Werkstatt' },
		{ id: 'Hochregallager', label: 'Hochregallager', value: 'Hochregallager' },
		{ id: 'Service', label: 'Service', value: 'Service' },
		{ id: 'Freiflächen', label: 'Freiflächen', value: 'Freiflächen' },
		{ id: 'Industriehalle', label: 'Industriehalle', value: 'Industriehalle' },
		{ id: 'Speditionslager', label: 'Speditionslager', value: 'Speditionslager' },
		{ id: 'Kühlhaus', label: 'Kühlhaus', value: 'Kühlhaus' }
	],
	[GewerbeImmobilie.AGRICULTURE]: [
		{
			id: 'Landwirtschaftliches Betrieb',
			label: 'Landwirtschaftliches Betrieb',
			value: 'Landwirtschaftliches Betrieb'
		},
		{ id: 'Anwesen', label: 'Anwesen', value: 'Anwesen' },
		{ id: 'Bauernhof', label: 'Bauernhof', value: 'Bauernhof' },
		{ id: 'Aussiedlerhof', label: 'Aussiedlerhof', value: 'Aussiedlerhof' },
		{ id: 'Gartenbau', label: 'Gartenbau', value: 'Gartenbau' },
		{ id: 'Ackerbau', label: 'Ackerbau', value: 'Ackerbau' },
		{ id: 'Weinbau', label: 'Weinbau', value: 'Weinbau' },
		{ id: 'Viehwirtschaft', label: 'Viehwirtschaft', value: 'Viehwirtschaft' },
		{ id: 'Jagd und Forstwirtschaft', label: 'Jagd und Forstwirtschaft', value: 'Jagd und Forstwirtschaft' },
		{ id: 'Teich und Fischwirtschaft', label: 'Teich und Fischwirtschaft', value: 'Teich und Fischwirtschaft' },
		{ id: 'Scheune', label: 'Scheune', value: 'Scheune' },
		{ id: 'Reiterhof', label: 'Reiterhof', value: 'Reiterhof' },
		{
			id: 'Sonstige Landwirtschaftsimmobilie',
			label: 'Sonstige Landwirtschaftsimmobilie',
			value: 'Sonstige Landwirtschaftsimmobilie'
		}
	],
	[GewerbeImmobilie.LEISURE_PROPERTY]: [
		{ id: 'Sportanlagen', label: 'Sportanlagen', value: 'Sportanlagen' },
		{ id: 'Vergnügungspark und Center', label: 'Vergnügungspark und Center', value: 'Vergnügungspark und Center' },
		{ id: 'Freizeitanlage', label: 'Freizeitanlage', value: 'Freizeitanlage' }
	],
	[GewerbeImmobilie.INVESTMENT]: [
		{ id: 'Mehrfamilienhaus', label: 'Mehrfamilienhaus', value: 'Mehrfamilienhaus' },
		{ id: 'Wohn und Geschäftshaus', label: 'Wohn und Geschäftshaus', value: 'Wohn und Geschäftshaus' },
		{ id: 'Geschäftshaus', label: 'Geschäftshaus', value: 'Geschäftshaus' },
		{ id: 'Bürogebäude', label: 'Bürogebäude', value: 'Bürogebäude' },
		{ id: 'SB-Markt', label: 'SB-Markt', value: 'SB-Markt' },
		{ id: 'Einkaufscentrum', label: 'Einkaufscentrum', value: 'Einkaufscentrum' },
		{ id: 'Wohnanlage', label: 'Wohnanlage', value: 'Wohnanlage' },
		{ id: 'Verbrauchermarkt', label: 'Verbrauchermarkt', value: 'Verbrauchermarkt' },
		{ id: 'Industrieanlage', label: 'Industrieanlage', value: 'Industrieanlage' }
	]
};

export const ParkflächeAccommodationOption = [
	{ id: 'Parkhaus', label: parkingOptions.PARKING_GARAGE, value: parkingOptions.PARKING_GARAGE },
	{ id: 'Garage', label: parkingOptions.GARAGE, value: parkingOptions.GARAGE },
	{ id: 'Parkplatz', label: parkingOptions.PARKING_AREA, value: parkingOptions.PARKING_AREA }
	// { id: 'Tankstelle', label: parkingOptions.GAS_STATION, value: parkingOptions.GAS_STATION },
	// { id: 'Sonstiges', label: parkingOptions.MISCELLANEOUS, value: parkingOptions.MISCELLANEOUS }
];
export const grundstückAccommodationOption = [
	{
		id: 'Wohngrundstück',
		value: landOptions.RESIDENTIAL_PROPERTY,
		label: landOptions.RESIDENTIAL_PROPERTY
	},
	{
		id: 'Gewerbegrundstück',
		value: landOptions.COMMERCIAL_PROPERTY,
		label: landOptions.COMMERCIAL_PROPERTY
	},
	{
		id: 'Gemischtes Grundstück',
		value: landOptions.MIXED_PROPERTY,
		label: landOptions.MIXED_PROPERTY
	},
	{
		id: 'Forst',
		value: landOptions.FOREST,
		label: landOptions.FOREST
	},
	{
		id: 'Landwirtschaft',
		value: landOptions.AGRICULTURE,
		label: landOptions.AGRICULTURE
	},
	{
		id: 'Grünland',
		value: landOptions.GRASSLAND,
		label: landOptions.GRASSLAND
	}
];

export enum propertyOption {
	apartment = 'Wohnung',
	house = 'Haus',
	land = 'Grundstück',
	commercial = 'Gewerbeimmobilie',
	parking = 'Parkplätze'
}

export const landTypes = [propertyOption.land, propertyOption.parking, 'plotbuy'];
export const propertyTabRadioButtonOption = [
	{
		label: propertyOption.apartment,
		value: propertyOption.apartment,
		icon: <ApartmentIcon />
	},
	{
		label: propertyOption.house,
		value: propertyOption.house,
		icon: <HouseIcon />
	},
	{
		label: propertyOption.land,
		value: propertyOption.land,
		icon: <PropertyIcon />
	},
	{
		label: propertyOption.commercial,
		value: propertyOption.commercial,
		icon: <CommercialIcon />
	},
	{
		label: propertyOption.parking,
		value: propertyOption.parking,
		icon: <ParkingIcon />
	}
];

export enum propertyTab {
	addPropertyTab = 'addPropertyTab',
	generally = 'Allgemein',
	location = 'Lage der Immobile',
	locationLand = 'Lage des Grundstücks',
	detail = 'Details der Immobilie',
	contact = 'Kontaktdaten',
	finance = 'Finanzen'
}

export const generallyTabCheckBox = [{ label: 'Wohnen' }, { label: 'Gewerbe' }];

export const apartmentDetailTabAreaField = [
	{
		label: 'Wohnfläche',
		name: 'areaLiving',
		placeholder: 'Wohnfläche'
	},
	{
		label: 'Anzahl Zimmer',
		name: 'noOfRooms',
		placeholder: 'Anzahl zimmer'
	},
	{
		label: 'Baujahr',
		name: 'constructionYear',
		placeholder: 'Baujahr'
	},
	{
		label: 'Bezugsfrei ab',
		name: 'vacantFrom',
		placeholder: ''
	}
];
export const houseDetailTabAreaField = [
	{
		label: 'Wohnfläche',
		name: 'areaLiving',
		placeholder: 'Wohnfläche'
	},
	{
		label: 'Anzahl Zimmer',
		name: 'noOfRooms',
		placeholder: 'Anzahl zimmer'
	},
	{
		label: 'Baujahr',
		name: 'constructionYear',
		placeholder: 'Baujahr'
	},
	{
		label: 'Bezugsfrei ab',
		name: 'vacantFrom',
		placeholder: ''
	},
	{
		label: 'Grundstücksgröße',
		name: 'areaPlot',
		placeholder: 'Grundstücksgröße'
	}
];
export const landDetailTabAreaField = [
	{
		label: 'Grundstücksgröße',
		name: 'areaPlot',
		placeholder: 'Grundstücksgröße'
	}
];
export const parkingDetailTabAreaField = [
	{
		label: 'Baujahr',
		name: 'constructionYear',
		placeholder: 'Baujahr'
	},
	{
		label: 'Grundstücksgröße',
		name: 'areaPlot',
		placeholder: 'Grundstücksgröße'
	},
	{
		label: 'Bezugsfrei ab',
		name: 'vacantFrom',
		placeholder: ''
	}
];

export const furnishingOption = {
	BALCONY: 'Balkon',
	GARDEN: 'Garten',
	WINTER_GARDEN: 'Wintergarten',
	BASEMENT_CELLAR: 'Keller',
	EQUIPPED_KITCHEN: 'Einbauküche',
	CENTRAL_HEATING: 'Zentralheizung',
	ROOF_TERRACE: 'Dachterasse',
	GUEST_TOILET: 'Guest-WC',
	TERRACE: 'Terrasse'
};
export const ausstattungField = [
	{ label: furnishingOption.BALCONY, name: 'equipBalcony' },

	{ label: furnishingOption.GARDEN, name: 'equipGarden' },

	{ label: furnishingOption.WINTER_GARDEN, name: 'equipWinterGarden' },

	{ label: furnishingOption.BASEMENT_CELLAR, name: 'objCellar' },

	{ label: furnishingOption.EQUIPPED_KITCHEN, name: 'equipKitchen' },

	{ label: furnishingOption.CENTRAL_HEATING, name: 'objHeatingType' },

	{ label: furnishingOption.ROOF_TERRACE, name: 'equipRoofTerrace' },

	{ label: furnishingOption.GUEST_TOILET, name: 'equipGuestToilet' },

	{ label: furnishingOption.TERRACE, name: 'equipTerrace' }
];

export enum contactTabRole {
	propertyOwner = 'PROPERTY_OWNER',
	professionalBroker = 'USER_REALTOR'
}
export const contactTabRadioButtonOptions = [
	{ label: 'Immobilienbesitzer', value: contactTabRole.propertyOwner },
	{ label: 'Professioneller Makler', value: contactTabRole.professionalBroker }
];

export const marketingTypeOption = [
	{
		id: 'Vermietung',
		value: 'Rent',
		label: 'Vermietung'
	},
	{
		id: 'Verkauf',
		value: 'Sell',
		label: 'Verkauf'
	}
];

export const currencyField = [
	{
		label: 'Mietpreis',
		name: 'totalRent',
		placeholder: 'Mietpreis'
	},
	{
		label: 'Wie hoch ist die Kaution?',
		name: 'depositCost',
		placeholder: 'Wie hoch ist die Kaution?'
	}
];

export const verkaufCurrencyField = [
	{
		label: 'Kaufpreis',
		name: 'totalRent',
		placeholder: 'Kaufpreis'
	}
];
export const pdfFields = [
	{
		label: 'Grundriss',
		name: 'propFloorPlan',
		id: 'layout-input'
	},
	{ label: 'Energieausweis', name: 'energyCertificate', id: 'expose-input' }
];
export const landPdfFields = [
	{
		label: 'Grundriss',
		name: 'propFloorPlan',
		id: 'layout-input'
	}
];

export const ParkingPdfFields = [
	{
		label: '',
		name: '',
		id: ''
	}
];
