import pin from 'assets/images/pin.png';
import pinHighlight from 'assets/images/pin-highlight.png';
import plotImage from 'assets/images/plot-image.png';
import tipTile from 'assets/images/placeholder.png';

import { formatCurrency, propertyOptions, propertyType } from 'utils';

export const isPlot = (type: string) => [propertyType.PLOT, 'plotbuy'].includes(type);

export const isParking = (type: string) =>
	[propertyType.PARKING, 'parkingarea', 'parkingarearent', 'parkingareabuy'].includes(type);

export const setProperties = (propertyList: any, statemapData: any) => {
	let list: any = [];
	let mapData: any = [];
	let mapResult = null;

	propertyList.forEach((prop: any) => {
		const defaultImage = prop.objectType === propertyType.PLOT || isPlot(prop.object_type) ? plotImage : tipTile;
		let imgList: any = [];
		let objectImage: any = [defaultImage];

		if (prop.objectPictures && prop.objectPictures.length > 0) {
			objectImage = [...prop.objectPictures];
			prop.objectPictures.forEach((element: string) => {
				const images = {
					original: element
				};
				imgList.push(images);
			});
		} else {
			imgList.push({ original: defaultImage });
		}

		const a = {
			id: prop.id,
			title: prop.objectTitle,
			coverImg: prop.objectTitlePicture,
			adrPostalCode: prop.adrPostalCode,
			price: prop.formatedPrice,
			propertyType: prop.propertyType,
			priceCurrency: prop.priceCurrency,
			rooms: prop.propRoomsAmount,
			areaLiving: prop.areaLiving || prop.areaPlot,
			imgList,
			adrQuarter: prop.adrQuarter,
			adrStreet: prop.adrStreet,
			yearConstructed: prop.yearConstructed,
			noMap: !prop.location ? true : false,
			isActive: prop.isActive,
			isEnable: prop.isEnable,
			isNewProperty: prop.isNewProperty,
			objectPictures: objectImage,
			type_auction_forced: prop.type_auction_forced,
			is_realtor_active: prop.is_realtor_active,
			is_user_matching_property: prop.is_user_matching_property,
			city: prop.city,
			totalRent: prop.totalRent,
			marketingType: prop.marketingType,
			object_type: prop.object_type
		};

		setMapData(prop, mapData, imgList);
		list.push(a);
	});

	if (mapData.length > 0 && statemapData) {
		mapResult = setMultiMapData(mapData, statemapData);
	}

	return { list, mapResult };
};

export const setMyProperties = (propertyList: any) => {
	let list: any = [];

	propertyList.forEach((prop: any) => {
		const defaultImage = prop.objectType === propertyType.PLOT || isPlot(prop.object_type) ? plotImage : tipTile;
		let imgList: any = [defaultImage];
		if (prop.objectPictures && prop.objectPictures.length > 0) {
			imgList = [...prop.objectPictures];
		}

		const a = {
			...prop,
			id: prop.id,
			title: prop.objectTitle,
			coverImg: prop.objectTitlePicture,
			adrPostalCode: prop.adrPostalCode,
			price: prop.formatedPrice,
			propertyType: prop.propertyType,
			priceCurrency: prop.priceCurrency,
			rooms: prop.propRoomsAmount,
			areaLiving: prop.areaLiving || prop.areaPlot,
			imgList,
			adrQuarter: prop.adrQuarter,
			adrStreet: prop.adrStreet,
			yearConstructed: prop.yearConstructed,
			noMap: !prop.location ? true : false,
			isActive: prop.isActive,
			isEnable: prop.isEnable,
			isNewProperty: prop.isNewProperty,
			objectPictures: imgList,
			type_auction_forced: prop.type_auction_forced,
			is_realtor_active: prop.is_realtor_active,
			is_user_matching_property: prop.is_user_matching_property,
			city: prop.city,
			totalRent: prop.totalRent
		};

		list.push(a);
	});

	return list;
};

export const setMapData = (prop: any, mapData: any, imgList: any[]) => {
	if (prop.location && prop.isActive && prop.isEnable) {
		const mapdata = {
			id: prop.id,
			title: prop.objectTitle,
			name: prop.objectTitle && prop.objectTitle.substring(0, 25) + '...',
			lat: parseFloat(prop.location.lat).toFixed(7),
			lon: parseFloat(prop.location.lon).toFixed(7),
			coverImg: prop.objectTitlePicture
				? prop.objectTitlePicture
				: prop.objectType === propertyType.PLOT || (isPlot(prop.object_type) && plotImage),
			imgList,
			price: prop.formatedPrice || formatCurrency(prop.totalRent),
			priceLabel: prop.marketingType === propertyOptions.RENT ? 'Mietpreis' : 'Kaufpreis',
			rooms: prop.propRoomsAmount,
			areaLiving: prop.areaLiving,
			propertyType: prop.propertyType,
			adr: `${prop.adrPostalCode && prop.adrPostalCode + ','} ${prop.adrQuarter || prop.city}`,
			icon: pin,
			highlightIcon: pinHighlight
		};
		mapData.push(mapdata);
	}
};

export const setMultiMapData = (mapData: any, statemapData: any) => {
	let singlePins: any = [];
	let multiPins = null;
	let group: any = null;
	let newArray: any = [];

	group = mapData.reduce((r: any, a: any) => {
		r[a.lat] = [...(r[a.lat] || []), a];
		if (a && r[a.lat] && r[a.lat].length === 1) {
			singlePins.push(a);
		}
		return r;
	}, {});

	// remove this later
	statemapData.forEach((element: any) => {
		singlePins = singlePins.filter((i: any) => element.lat !== i.lat && element.lon !== i.lon);
	});

	multiPins = Object.keys(group)
		.filter((i) => group[i].length > 1)
		.reduce((obj, key) => {
			//@ts-ignore
			obj[key] = group[key];
			return obj;
		}, {});

	const mapResult = createMultipleMapObject(multiPins, newArray, singlePins);
	return mapResult;
};

export const createMultipleMapObject = (multiPins: any, newArray: any, singlePins: any) => {
	let alreadyAdded: any = [];
	Object.entries(multiPins).forEach((pin: any) => {
		let id: string[] = [];
		let title: string[] = [];
		let coverImg: string[] = [];
		let price: string[] = [];
		let rooms: number[] = [];
		let areaLiving: string[] = [];
		let adr: string[] = [];
		let propertyType: string[] = [];
		let imgList: string[] = [];
		let priceLabel: string[] = [];

		pin[1].forEach((p: any) => {
			id.push(p.id);
			title.push(p.title);
			coverImg.push(p.coverImg);
			price.push(p.price);
			rooms.push(p.rooms);
			areaLiving.push(p.areaLiving);
			adr.push(p.adr);
			imgList.push(p.imgList);
			alreadyAdded.push(p.id);
			propertyType.push(p.propertyType);
			priceLabel.push(p.priceLabel);
		});

		const multiMapData = {
			id,
			title,
			coverImg,
			price,
			priceLabel,
			rooms,
			adr,
			imgList,
			areaLiving,
			propertyType,
			lat: pin[1][0].lat,
			lon: pin[1][0].lon
		};

		newArray.push(multiMapData);
	});

	singlePins.forEach((pin: any, index: number) => {
		newArray.forEach((i: any) => {
			if (pin.lat === i.lat && pin.lon === i.lon) {
				singlePins.splice(index, 1);
			}
		});
	});

	alreadyAdded.forEach((element: any) => {
		const remaining = singlePins.filter((i: any) => i.id === element);
		if (remaining.length > 0) {
			const index = singlePins.findIndex((i: any) => i.id === remaining[0].id);
			singlePins.splice(index, 1);
		}
	});

	return {
		singlePins,
		newArray
	};
};

export const openObjectDetail = (event: any, id: string) => {
	const classes = ['fa fa-heart-o', 'fa fa-close', 'fa fa-heart', 'like-icon text-center pointer'];
	if (
		event.target.className.animVal ||
		event.target.className.animVal === '' ||
		classes.includes(event.target.className)
	) {
		return false;
	}

	const win = window.open(`/objekt/${id}`, '_blank');
	win && win.focus();
};
