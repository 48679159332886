import {
	apartmentOptions,
	commercialOptions,
	furnishingOption,
	houseOptions,
	landOptions,
	parkingOptions
} from 'components/newMyProperty/utils/constants';
import englishText from 'languages/english';

export const lang = {
	EN: 'en',
	GR: 'de'
};

export const cities = {
	HAM: 'Hamburg',
	BER: 'Berlin',
	HANNOVER: 'Hannover',
	LEIPZIG: 'Leipzig'
};
export const state = {
	BER: 'Berlin',
	BAV: 'Bavaria',
	SAX: 'Saxony',
	THU: 'Thuringia',
	BAW: 'Baden-Württemberg',
	MVE: 'Mecklenburg-Vorpommern',
	RHP: 'Rhineland-Palatinate',
	HAM: 'Hamburg',
	SCH: 'Schleswig-Holstein',
	NRW: 'North Rhine-Westphalia',
	SAA: 'Saxony-Anhalt',
	HES: 'Hessen',
	SAL: 'Saarland',
	LOS: 'Lower Saxony',
	BRE: 'Bremen',
	BRA: 'Brandenburg'
};

export const primaryColor = '#3f6da7';
export const secondaryColor = 'rgba(55, 75, 106, 1)';

export const modalNames = {
	SORT_MENU: 'short_menu',
	SMALL_FILTER: 'small_filter',
	MORE_FILTER: 'more_filter',
	NOTIFICATION: 'notification_modal'
};

export const legendTypes = ['schools', 'supermarkets', 'transports', 'medicals', 'parks'];

export const sortOrder = {
	DESC: 'DESC',
	ASC: 'ASC'
};

export const sortData = {
	sortList: [
		{ label: englishText.STANDARD_SORTING, value: 'created_at' },
		{ label: englishText.PRICE_FILTER, value: 'price' },
		{ label: englishText.PRICE_PER_METER, value: 'pricePerSQM' },
		{ label: englishText.LIVING_SPACE, value: 'livingSpace' },
		{ label: englishText.ROOMS, value: 'rooms' },
		{ label: englishText.YEAR_OF_CONSTRUCTION, value: 'yearsOfConstruction' }
	],
	selectedSortType: sortOrder.DESC
};

export const propertyType = {
	HOUSE: 'Houses',
	APT: 'Apartment',
	PLOT: 'Grundstück',
	COMMERCIAL: 'Gewerbeimmobilie',
	PARKING: 'Parkplätze'
};

export const propertyOptions = {
	RENT: 'Rent',
	SELL: 'Sell',
	BUY: 'Buy'
};
export const houseTypesArray = [
	// For houses tab
	{ label: houseOptions.TOWN_HOUSE, value: false, propTypeFor: propertyType.HOUSE },
	{
		label: houseOptions.TOWNHOUSE,
		value: false,
		propTypeFor: propertyType.HOUSE
	},
	{
		label: houseOptions.DETACHED_HOUSE,
		value: false,
		propTypeFor: propertyType.HOUSE
	},
	{ label: houseOptions.BUNGALOW, value: false, propTypeFor: propertyType.HOUSE },
	{
		label: houseOptions.TWO_FAMILY_HOUSE,
		value: false,
		propTypeFor: propertyType.HOUSE
	},
	{
		label: houseOptions.VILLA,
		value: false,
		propTypeFor: propertyType.HOUSE
	},
	{
		label: houseOptions.SEMI_DETACHED_HOUSE,
		value: false,
		propTypeFor: propertyType.HOUSE
	},
	{
		label: houseOptions.APARTMENT_BUILDING,
		value: false,
		propTypeFor: propertyType.HOUSE
	},

	// For apartment tab
	{
		label: apartmentOptions.ATTIC_APARTMENT,
		value: false,
		propTypeFor: propertyType.APT
	},
	{
		label: apartmentOptions.APARTMENT,
		value: false,
		propTypeFor: propertyType.APT
	},
	{ label: apartmentOptions.MAISONETTE_APARTMENT, value: false, propTypeFor: propertyType.APT },
	{
		label: apartmentOptions.GROUND_FLOOR_APARTMENT,
		value: false,
		propTypeFor: propertyType.APT
	},
	{ label: apartmentOptions.LOFT_STUDIO_STUDIO, value: false, propTypeFor: propertyType.APT },
	{ label: apartmentOptions.BASEMENT_APARTMENT, value: false, propTypeFor: propertyType.APT },
	{ label: apartmentOptions.PENTHOUSE, value: false, propTypeFor: propertyType.APT },
	{ label: apartmentOptions.TERRACE_APARTMENT, value: false, propTypeFor: propertyType.APT },

	//for plot tab

	{
		label: landOptions.RESIDENTIAL_PROPERTY,
		value: false,
		propTypeFor: propertyType.PLOT
	},
	{
		label: landOptions.AGRICULTURE,
		value: false,
		propTypeFor: propertyType.PLOT
	},
	{
		label: landOptions.COMMERCIAL_PROPERTY,
		value: false,
		propTypeFor: propertyType.PLOT
	},
	// {
	//   label: apartmentOptions.GROUND_FLOOR_APARTMENT,
	//   value: false,
	//   propTypeFor: propertyType.PLOT
	// },
	{
		label: landOptions.MIXED_PROPERTY,
		value: false,
		propTypeFor: propertyType.PLOT
	},
	{
		label: landOptions.GRASSLAND,
		value: false,
		propTypeFor: propertyType.PLOT
	},
	{
		label: landOptions.FOREST,
		value: false,
		propTypeFor: propertyType.PLOT
	},

	//for Parking tab

	{
		label: parkingOptions.GARAGE,
		value: false,
		propTypeFor: propertyType.PARKING
	},
	{
		label: parkingOptions.PARKING_AREA,
		value: false,
		propTypeFor: propertyType.PARKING
	},
	{
		label: parkingOptions.PARKING_GARAGE,
		value: false,
		propTypeFor: propertyType.PARKING
	},

	//for Commercial tab
	{
		label: commercialOptions.OFFICE,
		value: false,
		propTypeFor: propertyType.COMMERCIAL
	},
	{
		label: commercialOptions.RETAIL,
		value: false,
		propTypeFor: propertyType.COMMERCIAL
	},
	{
		label: commercialOptions.GUEST_TRADE,
		value: false,
		propTypeFor: propertyType.COMMERCIAL
	},
	{
		label: commercialOptions.WAREHOUSE,
		value: false,
		propTypeFor: propertyType.COMMERCIAL
	},
	{
		label: commercialOptions.AGRICULTURE,
		value: false,
		propTypeFor: propertyType.COMMERCIAL
	},
	{
		label: commercialOptions.LEISURE_PROPERTY,
		value: false,
		propTypeFor: propertyType.COMMERCIAL
	},
	{
		label: commercialOptions.INVESTMENT,
		value: false,
		propTypeFor: propertyType.COMMERCIAL
	}
];

export const extraFeatures = [
	{
		label: furnishingOption.BALCONY,
		value: false,
		countName: 'equipBalcony'
	},
	{
		label: furnishingOption.GARDEN,
		value: false,
		countName: 'equipGarden'
	},
	{
		label: furnishingOption.WINTER_GARDEN,
		value: false,
		countName: 'equipWinterGarden'
	},
	{
		label: furnishingOption.BASEMENT_CELLAR,
		value: false,
		countName: 'objCellar'
	},
	{
		label: furnishingOption.EQUIPPED_KITCHEN,
		value: false,
		countName: 'equipKitchen'
	},
	{
		label: furnishingOption.CENTRAL_HEATING,
		value: false,
		countName: 'objHeatingType'
	},
	{
		label: furnishingOption.ROOF_TERRACE,
		value: false,
		countName: 'equipRoofTerrace'
	},
	{
		label: furnishingOption.GUEST_TOILET,
		value: false,
		countName: 'equipGuestToilet'
	},
	{
		label: furnishingOption.TERRACE,
		value: false,
		countName: 'equipTerrace'
	}
];

export const selectedLang = lang.GR;

export const searchPropertyTypes = {
	[propertyOptions.RENT]: 'MIETEN',
	[propertyOptions.SELL]: 'KAUFEN',
	[propertyOptions.BUY]: 'KAUFEN'
};

export const myPropertyTypes = {
	[propertyOptions.RENT]: 'ZUR VERMIETUNG',
	[propertyOptions.SELL]: 'ZUM VERKAUF'
};
