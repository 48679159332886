import React, { PropsWithChildren } from 'react';

// import { LogoLoader } from 'components/commonComponents/SkeletonLogo/logoLoader';
import HomeFooter from 'components/layout/footer';
import HomeHeader from 'components/layout/header';

const Layout: React.FC<PropsWithChildren> = (props) => {
	// const [isScriptLoaded, setIsScriptLoaded] = useState(false);

	// useEffect(() => {
	// 	const handleLoad = () => setIsScriptLoaded(true);
	// 	window.addEventListener('load', handleLoad);

	// 	const googleMapsScript = document.createElement('script');
	// 	googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=places&language=de&region=DE`;
	// 	googleMapsScript.async = true;
	// 	// googleMapsScript.defer = true;
	// 	googleMapsScript.onload = handleLoad;

	// 	window.document.body.appendChild(googleMapsScript);

	// 	return () => {
	// 		window.removeEventListener('load', handleLoad);
	// 		if (googleMapsScript.parentNode) {
	// 			window.document.body.removeChild(googleMapsScript);
	// 		}
	// 	};
	// }, []);

	return (
		<div className='layout-container'>
			{/* <Helmet>{loadScripts()}</Helmet> */}
			{/* <Suspense fallback={<LogoLoader />}>
				{!isScriptLoaded && <LogoLoader />}
				{isScriptLoaded && ( */}
			<div className='main-section'>
				<HomeHeader />
				{props.children}
				<HomeFooter />
			</div>
			{/* )}
			</Suspense> */}
		</div>
	);
};

export default Layout;
